import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../../../components/Footer';
import '../../../styles/Pages.css';

const PatternRules: React.FC = () => {
  return (
    <div className="page-container">
      <Link to="/docs" className="back-to-home">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
          <path d="M19 12H5M12 19l-7-7 7-7"/>
        </svg>
        Back to Documentation
      </Link>
      <main className="content-container">
        <article className="content-section documentation">
          <h1>Pattern-Based Detection</h1>
          
          <section className="doc-intro">
            <h2>Understanding Pattern Rules</h2>
            <p>
              Pattern rules are predefined regular expressions that automatically detect and mask common
              formats of sensitive information. These rules are designed to identify structured data
              patterns without requiring manual configuration.
            </p>
          </section>

          <section className="doc-section">
            <h2>How Pattern Rules Work</h2>
            <p>
              Each pattern rule consists of:
            </p>
            <ul className="spaced-list">
              <li>A regular expression pattern that matches specific data formats</li>
              <li>A mask value that replaces the matched text in masking mode</li>
              <li>A pool of realistic fake values for replacement mode</li>
              <li>A priority level that determines the order of processing</li>
            </ul>
            <p>
              When you input text, Info-Mask processes it through all pattern rules in order of
              priority, ensuring that more specific patterns are checked before general ones.
            </p>
          </section>

          <section className="doc-section">
            <h2>Available Pattern Rules</h2>
            
            <div className="rule-category">
              <h3>Network Identifiers</h3>
              
              <h4>IPv4 Addresses</h4>
              <p>Detects various types of IPv4 addresses with specific classifications:</p>
              <ul className="spaced-list">
                <li><strong>Internal IPs:</strong> Matches private network ranges (10.x.x.x, 172.16-31.x.x, 192.168.x.x)</li>
                <li><strong>Loopback:</strong> Identifies loopback addresses (127.x.x.x)</li>
                <li><strong>Reserved:</strong> Detects reserved ranges (0.x.x.x, 169.254.x.x, 192.0.0.x, 224-255.x.x.x)</li>
                <li><strong>External:</strong> Matches all other valid IPv4 addresses not in reserved ranges</li>
                <li><strong>Subnet Masks:</strong> Identifies valid subnet mask patterns (255.x.x.x where x is 0 or 255)</li>
              </ul>

              <h4>IPv6 Addresses</h4>
              <p>Identifies different categories of IPv6 addresses:</p>
              <ul className="spaced-list">
                <li><strong>Internal IPv6:</strong> Matches ULA addresses (fc00::/7, fd00::/8)</li>
                <li><strong>Link-Local:</strong> Detects link-local addresses (fe80::/10)</li>
                <li><strong>Multicast:</strong> Identifies multicast addresses (ff00::/8)</li>
                <li><strong>Global:</strong> Matches global unicast addresses (2000::/3)</li>
              </ul>

              <h4>MAC Addresses</h4>
              <p>Detects MAC addresses in standard formats:</p>
              <ul className="spaced-list">
                <li>Matches six groups of two hexadecimal digits</li>
                <li>Supports both colon (:) and hyphen (-) separators</li>
                <li>Case-insensitive matching</li>
              </ul>

              <h4>Domain Names</h4>
              <p>Identifies domain names and email addresses:</p>
              <ul className="spaced-list">
                <li><strong>Email Addresses:</strong> Matches standard email format (user@domain.tld)</li>
                <li><strong>Domain Names:</strong> Detects domains with valid TLDs</li>
                <li>Supports subdomains and international TLDs</li>
                <li>Excludes email addresses from domain-only matches</li>
              </ul>
            </div>

            <div className="rule-category">
              <h3>Authentication & Security</h3>
              
              <h4>AWS Identifiers</h4>
              <p>Detects AWS ARN (Amazon Resource Name) patterns:</p>
              <ul className="spaced-list">
                <li>Matches full ARN format: arn:aws:service:region:account-id:resource-type/resource-id</li>
                <li>Supports various AWS services (s3, iam, ec2, lambda, etc.)</li>
                <li>Includes account IDs and resource identifiers</li>
              </ul>

              <h4>JWT Tokens</h4>
              <p>Identifies JSON Web Tokens:</p>
              <ul className="spaced-list">
                <li>Matches three base64url-encoded segments separated by dots</li>
                <li>Each segment must be at least 8 characters long</li>
                <li>Supports various JWT algorithms (HS256, RS256, etc.)</li>
              </ul>

              <h4>Distinguished Names (DN)</h4>
              <p>Detects LDAP/X.500 DN components:</p>
              <ul className="spaced-list">
                <li><strong>CN (Common Name):</strong> User or service names</li>
                <li><strong>OU (Organizational Unit):</strong> Department or division names</li>
                <li><strong>DC (Domain Component):</strong> Domain parts</li>
                <li><strong>C (Country):</strong> Two-letter country codes</li>
                <li><strong>L (Locality):</strong> City names</li>
                <li><strong>ST (State):</strong> State or province names</li>
                <li><strong>O (Organization):</strong> Company or organization names</li>
                <li><strong>UID:</strong> User identifiers</li>
              </ul>
            </div>

            <div className="rule-category">
              <h3>Personal & Financial Information</h3>
              
              <h4>Credit Card Numbers</h4>
              <p>Identifies credit card numbers with formatting:</p>
              <ul className="spaced-list">
                <li>Matches groups of 4 digits separated by spaces or hyphens</li>
                <li>Last group can be 2-4 digits</li>
                <li>Supports major card formats (Visa, MasterCard, Amex)</li>
              </ul>

              <h4>UUIDs</h4>
              <p>Detects Universal Unique Identifiers:</p>
              <ul className="spaced-list">
                <li>Matches standard UUID format (8-4-4-4-12 hexadecimal digits)</li>
                <li>Case-insensitive matching</li>
                <li>Requires exact length and format with hyphens</li>
              </ul>
            </div>
          </section>

          <section className="doc-section">
            <h2>Pattern Rule Priority</h2>
            <p>
              Rules are processed in order of specificity to avoid false positives:
            </p>
            <ol className="spaced-list">
              <li>Domain patterns (most specific)</li>
              <li>JWT patterns</li>
              <li>Distinguished Name patterns</li>
              <li>Credit Card patterns</li>
              <li>UUID patterns</li>
              <li>AWS patterns</li>
              <li>MAC address patterns</li>
              <li>IPv6 patterns</li>
              <li>IPv4 patterns (most general)</li>
            </ol>
          </section>

          <section className="doc-section">
            <h2>Best Practices</h2>
            <ul className="spaced-list">
              <li>Review detected patterns in the summary section to ensure accuracy</li>
              <li>Remove any false positives before proceeding</li>
              <li>Use replacement mode for realistic-looking substitutes</li>
              <li>Combine with keyword rules for comprehensive protection</li>
              <li>Create custom rules for patterns not covered by default rules</li>
            </ul>
          </section>
        </article>
      </main>
      <Footer />
    </div>
  );
};

export default PatternRules; 