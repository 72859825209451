import { IPv4Pattern } from '../../types/patterns';
import { CATEGORIES } from '../categories';

// Get credit card masking and fake data from categories
const creditCardCategory = CATEGORIES.find(cat => cat.name === 'Financial Information');
const creditCardSubcategory = creditCardCategory?.subcategories.find(sub => sub.name === 'Credit Card Number');

export const CREDIT_CARD_PATTERNS: IPv4Pattern[] = [
    {
        name: 'credit_card',
        pattern: /\b(?:\d{4}[-\s]){3}\d{2,4}\b/g,
        priority: 1,
        maskValue: creditCardSubcategory?.maskedValue || '[Masked Credit Card]',
        fakeDataPool: creditCardSubcategory?.fakeData || [
            '4111-1111-1111-1111',
            '5500-0000-0000-0004',
            '3000-0000-0000-04',
            '3400 0000 0000 009',
            '5105-1051-0510 5100'
        ]
    }
]; 