import { KeywordPattern } from '../../types/patterns';

export const KEYWORD_PATTERNS: KeywordPattern[] = [
    {
        name: 'domain',
        logPattern: /['"]([a-zA-Z0-9_]*domain[a-zA-Z0-9_]*?)['"]?\s*:\s*(?:['"]([^'"]+)['"]|([^,}\s\n\r]+))/gi,
        codePattern: /([a-zA-Z0-9_]*domain[a-zA-Z0-9_]*?)\s*=\s*(?![\s{])((['"]?[^'"&}\s]+['"]?)|([^&}\s]+))/gi,
        maskValue: '[Masked Domain]',
        fakeDataPool: [
            'example-tech.com',
            'mocksite.net',
            'fakedomain.org',
            'testportal.io',
            'placeholderdomain.xyz',
            'devserver.cloud',
            'stagingenv.app',
            'sandboxdomain.dev',
            'demoplatform.co',
            'testinstance.site'
        ]
    },
    {
        name: 'company',
        logPattern: /['"]([a-zA-Z0-9_]*(?:tenant|organization|org|company|site)_?name[a-zA-Z0-9_]*?)['"]?\s*:\s*(?:['"]([^'"]+)['"]|([^,}\s\n\r]+))/gi,
        codePattern: /([a-zA-Z0-9_]*(?:tenant|organization|org|company|site)_?name[a-zA-Z0-9_]*?)\s*=\s*(?![\s{])((['"]?[^'"&}\s]+['"]?)|([^&}\s]+))/gi,
        maskValue: '[Masked Company Name]',
        fakeDataPool: [
            'TechNova Solutions',
            'Greenfield Enterprises',
            'Skyline Innovations',
            'Quantum Dynamics',
            'BluePeak Industries',
            'Digital Frontier Corp',
            'CloudWave Systems',
            'NextGen Technologies',
            'Apex Solutions Group',
            'GlobalTech Ventures'
        ]
    },
    {
        name: 'key',
        logPattern: /['"]?((?<![a-zA-Z0-9])(?:key|keys)(?![a-zA-Z])|(?:[a-zA-Z0-9_]*(?:key|keys))(?=\b(?!_)))['"]?\s*:\s*(?:['"]([^'"]+)['"]|([^,}\s\n\r]+))/gi,
        codePattern: /((?<![a-zA-Z0-9])(?:key|keys)(?![a-zA-Z])|(?:[a-zA-Z0-9_]*(?:key|keys))(?=\b(?!_)))\s*=\s*(?![\s{])((['"]?[^'"&}\s]+['"]?)|([^&}\s]+))/gi,
        maskValue: '[Masked Key]',
        fakeDataPool: [
            'API_KEY_123456789',
            'SECRET_KEY_ABCDEF',
            'ACCESS_KEY_XYZ789',
            'MASTER_KEY_456ABC',
            'PRIVATE_KEY_789XYZ',
            'PUBLIC_KEY_DEF123',
            'SYSTEM_KEY_GHI456',
            'APP_KEY_JKL789',
            'CLIENT_KEY_MNO123',
            'SERVICE_KEY_PQR456'
        ]
    },
    {
        name: 'token',
        logPattern: /['"]([a-zA-Z0-9_]*token[a-zA-Z0-9_]*?)['"]?\s*:\s*(?:['"]([^'"]+)['"]|([^,}\s\n\r]+))/gi,
        codePattern: /([a-zA-Z0-9_]*token[a-zA-Z0-9_]*?)\s*=\s*(?![\s{])((['"]?[^'"&}\s]+['"]?)|([^&}\s]+))/gi,
        maskValue: '[Masked Token]',
        fakeDataPool: [
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9',
            'Bearer_ABC123XYZ789',
            'TOKEN_987654321ABCDEF',
            'ACCESS_TOKEN_456GHIJKL',
            'REFRESH_789_TOKEN_MNOPQ',
            'SESSION_TOKEN_RSTUV123',
            'AUTH_TOKEN_WXYZ456789',
            'OAUTH_TOKEN_123ABCDEF',
            'JWT_TOKEN_GHI789JKL',
            'API_TOKEN_MNO123PQR'
        ]
    },
    {
        name: 'password',
        logPattern: /['"]([a-zA-Z0-9_]*(?:password|pass)[a-zA-Z0-9_]*?)['"]?\s*:\s*(?:['"]([^'"]+)['"]|([^,}\s\n\r]+))/gi,
        codePattern: /([a-zA-Z0-9_]*(?:password|pass)[a-zA-Z0-9_]*?)\s*=\s*(?![\s{])((['"]?[^'"&}\s]+['"]?)|([^&}\s]+))/gi,
        maskValue: '[Masked Password]',
        fakeDataPool: [
            'SecurePass123!@#',
            'StrongPwd456$%^',
            'SafeAccess789&*(',
            'ProtectedKey321)_+',
            'SecretEntry567<>?',
            'Complex123Pass!@#',
            'Unique456Pwd$%^',
            'Safe789Access&*(',
            'Secure321Key)_+',
            'Private567Entry<>?'
        ]
    },
    {
        name: 'secret',
        logPattern: /['"]([a-zA-Z0-9_]*secret[a-zA-Z0-9_]*?)['"]?\s*:\s*(?:['"]([^'"]+)['"]|([^,}\s\n\r]+))/gi,
        codePattern: /([a-zA-Z0-9_]*secret[a-zA-Z0-9_]*?)\s*=\s*(?![\s{])((['"]?[^'"&}\s]+['"]?)|([^&}\s]+))/gi,
        maskValue: '[Masked Secret]',
        fakeDataPool: [
            'SECRET_KEY_789ABCDEF',
            'APP_SECRET_123GHIJKL',
            'CLIENT_SECRET_456MNOPQ',
            'SECURE_TOKEN_RSTUVWX',
            'PRIVATE_KEY_YZ12345',
            'SYSTEM_SECRET_678ABC',
            'API_SECRET_DEF901GHI',
            'AUTH_SECRET_JKL234MNO',
            'OAUTH_SECRET_PQR567',
            'JWT_SECRET_STU890VWX'
        ]
    },
    {
        name: 'auth',
        logPattern: /['"]([a-zA-Z0-9_]*(?:auth|oauth)[a-zA-Z0-9_]*?)['"]?\s*:\s*(?:['"]([^'"]+)['"]|([^,}\n\r]+))/gi,
        codePattern: /([a-zA-Z0-9_]*(?:auth|oauth)[a-zA-Z0-9_]*?)\s*=\s*(?![\s{])((['"]?[^'"&}\s]+['"]?)|([^&}\s]+))/gi,
        maskValue: '[Masked Auth]',
        fakeDataPool: [
            'OAUTH_TOKEN_123ABC',
            'AUTH_CODE_456DEF',
            'BEARER_789GHI_XYZ',
            'ACCESS_AUTH_JKL123',
            'AUTH_SESSION_MNO456',
            'OAUTH2_TOKEN_PQR789',
            'AUTH_BEARER_STU123',
            'BASIC_AUTH_VWX456',
            'SESSION_AUTH_YZ789',
            'TOKEN_AUTH_123ABC'
        ]
    },
    {
        name: 'latitude',
        logPattern: /['"]([a-zA-Z0-9_]*latitude[a-zA-Z0-9_]*?)['"]?\s*:\s*"?(-?\b(?:[1-9]|[1-8][0-9]|90)(?:\.\d+)?\b|0\.\d*[1-9]\d*)"?/gi,
        codePattern: /([a-zA-Z0-9_]*latitude[a-zA-Z0-9_]*?)\s*=\s*"?(-?\b(?:[1-9]|[1-8][0-9]|90)(?:\.\d+)?\b|0\.\d*[1-9]\d*)"?/gi,
        maskValue: '[Masked Latitude]',
        fakeDataPool: [
            '40.7128',
            '34.0522',
            '51.5074',
            '48.8566',
            '35.6762',
            '41.8781',
            '52.5200',
            '25.2048',
            '37.7749',
            '55.7558'
        ]
    },
    {
        name: 'longitude',
        logPattern: /['"]([a-zA-Z0-9_]*longitude[a-zA-Z0-9_]*?)['"]?\s*:\s*"?(-?\b(?:[1-9]|[1-9][0-9]|1[0-7][0-9]|180)(?:\.\d+)?\b|0\.\d*[1-9]\d*)"?/gi,
        codePattern: /([a-zA-Z0-9_]*longitude[a-zA-Z0-9_]*?)\s*=\s*"?(-?\b(?:[1-9]|[1-9][0-9]|1[0-7][0-9]|180)(?:\.\d+)?\b|0\.\d*[1-9]\d*)"?/gi,
        maskValue: '[Masked Longitude]',
        fakeDataPool: [
            '-74.0060',
            '-118.2437',
            '-0.1278',
            '2.3522',
            '139.6503',
            '-87.6298',
            '13.4050',
            '55.2708',
            '-122.4194',
            '37.6173'
        ]
    },
    {
        name: 'username',
        logPattern: /['"]([a-zA-Z0-9_]*(?:username|user_name)[a-zA-Z0-9_]*?)['"]?\s*:\s*(?:['"]([^'"]+)['"]|([^,}\s\n\r]+))/gi,
        codePattern: /([a-zA-Z0-9_]*(?:username|user_name)[a-zA-Z0-9_]*?)\s*=\s*(?![\s{])((['"]?[^'"&}\s]+['"]?)|([^&}\s]+))/gi,
        maskValue: '[Masked Username]',
        fakeDataPool: [
            'jsmith123',
            'awhite_dev',
            'tech_admin',
            'mwilson85',
            'dev_user',
            'sarah_ops',
            'robert_qa',
            'emma_eng',
            'admin_sys',
            'david_dev'
        ]
    },
    {
        name: 'hostname',
        logPattern: /['"]([a-zA-Z0-9_]*(?:hostname|host_name)[a-zA-Z0-9_]*?)['"]?\s*:\s*(?:['"]([^'"]+)['"]|([^,}\s\n\r]+))/gi,
        codePattern: /([a-zA-Z0-9_]*(?:hostname|host_name)[a-zA-Z0-9_]*?)\s*=\s*(?![\s{])((['"]?[^'"&}\s]+['"]?)|([^&}\s]+))/gi,
        maskValue: '[Masked Hostname]',
        fakeDataPool: [
            'srv-prod-01',
            'app-dev-02',
            'db-master-01',
            'web-server-03',
            'auth-service-01',
            'cache-node-02',
            'api-gateway-01',
            'monitoring-01',
            'backup-srv-02',
            'load-balancer-01'
        ]
    }
]; 