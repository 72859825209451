import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';
import BackButton from '../components/BackButton';
import '../styles/Pages.css';

const Documentation: React.FC = () => {
  return (
    <div className="page-container">
      <BackButton to="/" text="Back to Home" />
      <div className="content-container">
        <article className="content-section documentation">
          <h1>Documentation</h1>
          <p>
            Learn how to use Info-Mask effectively with our comprehensive documentation.
            Discover how our tool can help protect sensitive information in your data.
          </p>

          <div className="doc-sections">
            <section className="doc-section">
              <h2>Getting Started</h2>
              <ul>
                <li><Link to="/docs/getting-started">Introduction to Info-Mask</Link></li>
                <li><Link to="/docs/removing-false-detections">Removing False Detections</Link></li>
              </ul>
            </section>

            <section className="doc-section">
              <h2>Detection Rules</h2>
              <ul>
                <li><Link to="/docs/custom-rules">Custom Rules Detection</Link></li>
                <li><Link to="/docs/detection-rules/patterns">Pattern-Based Detection</Link></li>
                <li><Link to="/docs/detection-rules/keywords">Keyword-Based Detection</Link></li>
              </ul>
            </section>

            <section className="doc-section">
              <h2>Use Cases</h2>
              <ul>
                <li><Link to="/docs/use-cases/soc-analyst">SOC Analyst Guide</Link></li>
              </ul>
            </section>
          </div>
        </article>
      </div>
      <Footer />
    </div>
  );
};

export default Documentation; 