import { PatternMatch, ValueMapping } from '../types/patterns';

export class PatternMasker {
  private valueMap: Map<string, ValueMapping>;
  private maskedRanges: { start: number; end: number }[];

  constructor() {
    this.valueMap = new Map();
    this.maskedRanges = [];
  }

  private isRangeOverlapping(start: number, end: number): boolean {
    return this.maskedRanges.some(range => 
      (start >= range.start && start < range.end) ||
      (end > range.start && end <= range.end) ||
      (start <= range.start && end >= range.end)
    );
  }

  private addMaskedRange(start: number, end: number) {
    this.maskedRanges.push({ start, end });
    // Sort ranges by start position for efficient checking
    this.maskedRanges.sort((a, b) => a.start - b.start);
  }

  private getOrCreateMapping(original: string, type: string, fakeDataPool: string[]): ValueMapping {
    // Normalize case for comparison
    const normalizedOriginal = original.toLowerCase();
    
    // Check if we have any mapping with this value (case insensitive)
    const existingMapping = Array.from(this.valueMap.values())
        .find(m => m.original.toLowerCase() === normalizedOriginal);
    
    if (existingMapping) {
        return existingMapping;
    }

    // Get unused fake data from the specific pool for this type
    const usedValues = new Set(
        Array.from(this.valueMap.values())
            .filter(m => m.type === type)  // Only consider used values of the same type
            .map(m => m.masked)
    );
    
    // Filter available fake data that hasn't been used for this type
    const availableFakeData = fakeDataPool.filter(data => !usedValues.has(data));
    
    // If we run out of fake data, generate new one or reuse from pool
    const fakeData = availableFakeData.length > 0 
        ? availableFakeData[Math.floor(Math.random() * availableFakeData.length)]
        : fakeDataPool[Math.floor(Math.random() * fakeDataPool.length)];

    const mapping: ValueMapping = {
        original,
        masked: fakeData,
        type
    };

    this.valueMap.set(original, mapping);
    return mapping;
  }

  public findMatches(text: string, pattern: RegExp): PatternMatch[] {
    const matches: PatternMatch[] = [];
    let match;

    while ((match = pattern.exec(text)) !== null) {
      if (!this.isRangeOverlapping(match.index, pattern.lastIndex)) {
        matches.push({
          value: match[0],
          type: 'ipv4', // This will be more specific in actual implementation
          start: match.index,
          end: pattern.lastIndex
        });
        
        // Add to masked ranges immediately to prevent other patterns from matching
        this.addMaskedRange(match.index, pattern.lastIndex);
      }
    }

    return matches;
  }

  public maskText(text: string, matches: PatternMatch[], maskValue: string): string {
    let result = text;
    let offset = 0;

    matches.forEach(match => {
      const adjustedStart = match.start + offset;
      const adjustedEnd = match.end + offset;
      
      result = result.slice(0, adjustedStart) + maskValue + result.slice(adjustedEnd);
      offset += maskValue.length - (match.end - match.start);
      
      this.addMaskedRange(adjustedStart, adjustedStart + maskValue.length);
    });

    return result;
  }

  public replaceText(text: string, matches: PatternMatch[], type: string, fakeDataPool: string[]): string {
    let result = text;
    let offset = 0;

    matches.forEach(match => {
      const mapping = this.getOrCreateMapping(match.value, type, fakeDataPool);
      const adjustedStart = match.start + offset;
      const adjustedEnd = match.end + offset;
      
      result = result.slice(0, adjustedStart) + mapping.masked + result.slice(adjustedEnd);
      offset += mapping.masked.length - (match.end - match.start);
      
      this.addMaskedRange(adjustedStart, adjustedStart + mapping.masked.length);
    });

    return result;
  }

  public reset() {
    this.valueMap.clear();
    this.maskedRanges = [];
  }

  public getMappings(): ValueMapping[] {
    return Array.from(this.valueMap.values());
  }

  public addMapping(mapping: ValueMapping) {
    this.valueMap.set(mapping.original, mapping);
  }

  public removeMapping(originalValue: string) {
    this.valueMap.delete(originalValue);
  }
} 