import React, { useEffect, useState } from 'react';
import '../styles/Components.css';
import { GA_TRACKING_ID } from '../hooks/useGoogleAnalytics';

const CookieConsent: React.FC = () => {
  const [showBanner, setShowBanner] = useState(false);

  // Function to remove Google Analytics cookies
  const removeGACookies = () => {
    const domain = window.location.hostname;
    document.cookie = `_ga=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${domain}`;
    document.cookie = `_ga_${GA_TRACKING_ID.replace('G-', '')}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${domain}`;
    const cookies = document.cookie.split(';');
    for (const cookie of cookies) {
      const cookieName = cookie.split('=')[0].trim();
      if (cookieName.startsWith('_ga')) {
        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${domain}`;
      }
    }
  };

  // Function to initialize GA with proper consent state
  const initializeGA = (consentGranted: boolean) => {
    // Remove any existing GA scripts
    const existingScripts = document.querySelectorAll('script[src*="googletagmanager"]');
    existingScripts.forEach(script => script.remove());

    const script1 = document.createElement('script');
    script1.async = true;
    script1.src = `https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`;

    const script2 = document.createElement('script');
    script2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('consent', 'default', {
        'analytics_storage': '${consentGranted ? 'granted' : 'denied'}'
      });
      gtag('config', '${GA_TRACKING_ID}', {
        'cookie_flags': 'SameSite=None;Secure',
        'cookie_domain': window.location.hostname,
        'cookie_expires': 2592000 // 30 days in seconds
      });
    `;

    // Only append scripts if consent is granted
    if (consentGranted) {
      document.head.appendChild(script1);
      document.head.appendChild(script2);
    }
  };

  useEffect(() => {
    // Check if user has already made a choice
    const consentChoice = localStorage.getItem('cookieConsent');
    
    if (consentChoice === null) {
      setShowBanner(true);
      // Ensure GA is not loaded until consent is given
      removeGACookies();
    } else if (consentChoice === 'rejected') {
      removeGACookies();
    } else if (consentChoice === 'accepted') {
      initializeGA(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'accepted');
    setShowBanner(false);
    initializeGA(true);
  };

  const handleReject = () => {
    localStorage.setItem('cookieConsent', 'rejected');
    setShowBanner(false);
    removeGACookies();
    initializeGA(false); // Initialize with consent denied
  };

  if (!showBanner) return null;

  return (
    <div className="cookie-consent">
      <div className="cookie-content">
        <p>
          We use cookies to analyze site usage and improve your experience. 
          See our <a href="/privacy-policy">Privacy Policy</a> for details.
        </p>
        <div className="cookie-buttons">
          <button onClick={handleReject} className="reject-button">
            Reject
          </button>
          <button onClick={handleAccept} className="accept-button">
            Accept
          </button>
        </div>
      </div>
    </div>
  );
};

export default CookieConsent; 