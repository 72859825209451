import { IPv4Pattern } from '../../types/patterns';

export const DN_PATTERNS: IPv4Pattern[] = [
    {
        name: 'dn_cn',
        pattern: /(?<=CN=)([^,"\s][^,]*)/gi,
        priority: 1,
        maskValue: '[Masked CN]',
        fakeDataPool: [
            'John Smith',
            'Alice Johnson',
            'Robert Brown',
            'Emma Davis',
            'Michael Wilson',
            'Sarah Anderson',
            'David Taylor',
            'Lisa Martinez',
            'James Thompson',
            'Jennifer Garcia'
        ]
    },
    {
        name: 'dn_ou',
        pattern: /(?<=OU=)([^,"\s][^,]*)/gi,
        priority: 2,
        maskValue: '[Masked OU]',
        fakeDataPool: [
            'IT Department',
            'Human Resources',
            'Engineering',
            'Sales',
            'Marketing',
            'Research',
            'Development',
            'Operations',
            'Support',
            'Administration'
        ]
    },
    {
        name: 'dn_dc',
        pattern: /(?<=DC=)([^,"\s][^,]*)/gi,
        priority: 3,
        maskValue: '[Masked DC]',
        fakeDataPool: [
            'example',
            'company',
            'organization',
            'enterprise',
            'corp',
            'local',
            'internal',
            'domain',
            'private',
            'global'
        ]
    },
    {
        name: 'dn_c',
        pattern: /(?<=C=)([^,"\s][^,]*)/gi,
        priority: 4,
        maskValue: '[Masked C]',
        fakeDataPool: [
            'US',
            'GB',
            'DE',
            'FR',
            'JP',
            'CA',
            'AU',
            'IT',
            'ES',
            'NL'
        ]
    },
    {
        name: 'dn_l',
        pattern: /(?<=L=)([^,"\s][^,]*)/gi,
        priority: 5,
        maskValue: '[Masked L]',
        fakeDataPool: [
            'New York',
            'London',
            'Berlin',
            'Paris',
            'Tokyo',
            'Toronto',
            'Sydney',
            'Rome',
            'Madrid',
            'Amsterdam'
        ]
    },
    {
        name: 'dn_st',
        pattern: /(?<=ST=)([^,"\s][^,]*)/gi,
        priority: 6,
        maskValue: '[Masked ST]',
        fakeDataPool: [
            'New York',
            'California',
            'Texas',
            'Florida',
            'Illinois',
            'Ontario',
            'Queensland',
            'Bavaria',
            'Lombardy',
            'Catalonia'
        ]
    },
    {
        name: 'dn_o',
        pattern: /(?<=O=)([^,"\s][^,]*)/gi,
        priority: 7,
        maskValue: '[Masked O]',
        fakeDataPool: [
            'Acme Corporation',
            'Global Industries',
            'Tech Solutions',
            'First Enterprise',
            'International Corp',
            'Systems Inc',
            'Digital Group',
            'Network Solutions',
            'Data Systems',
            'Cloud Services'
        ]
    },
    {
        name: 'dn_uid',
        pattern: /(?<=(?:^|,\s*)UID=)([^,"\s][^,]*)/gi,
        priority: 8,
        maskValue: '[Masked UID]',
        fakeDataPool: [
            'user123',
            'admin456',
            'tech789',
            'dev012',
            'ops345',
            'support678',
            'sys901',
            'net234',
            'sec567',
            'app890'
        ]
    }
]; 