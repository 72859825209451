import { IPv4Pattern } from '../../types/patterns';

export const IPv6_PATTERNS: IPv4Pattern[] = [
    {
        name: 'internal_ipv6',
        pattern: /\bfc[0-9A-Fa-f]{2}:(?:[0-9A-Fa-f:]+)\b|\bfd[0-9A-Fa-f]{2}:(?:[0-9A-Fa-f:]+)\b/g,
        priority: 1,
        maskValue: '[Masked Internal IPv6]',
        fakeDataPool: [
            'fc00::1',
            'fd12:3456:789a:abcd::1',
            'fdff:ffff:ffff:ffff:ffff:ffff:ffff:ffff',
            'fc10:1234:5678::abcd',
            'fd00:1:2:3:4:5:6:7',
            'fc01:2345:6789::1',
            'fd11:2233:4455:6677::1',
            'fc20:dead:beef::1',
            'fd22:1:2:3::1',
            'fc30:cafe:babe::1'
        ]
    },
    {
        name: 'reserved_ipv6',
        pattern: /\bfe80:(?:[0-9A-Fa-f]{0,4}:){0,6}[0-9A-Fa-f]{1,4}\b/g,
        priority: 2,
        maskValue: '[Masked Reserved IPv6]',
        fakeDataPool: [
            'fe80::1',
            'fe80::abcd:1234:5678:90ef',
            'fe80::a2:64ff:fe99:abcd',
            'fe80::dead:beef:cafe:1234',
            'fe80::42:ff:fe99:1',
            'fe80::1111:2222:3333:4444',
            'fe80::5555:6666:7777:8888',
            'fe80::9999:aaaa:bbbb:cccc',
            'fe80::dddd:eeee:ffff:0000',
            'fe80::1234:5678:90ab:cdef'
        ]
    },
    {
        name: 'multicast_ipv6',
        pattern: /\bff[0-9A-Fa-f]{2}:(?:[0-9A-Fa-f:]+)\b/g,
        priority: 3,
        maskValue: '[Masked Multicast IPv6]',
        fakeDataPool: [
            'ff02::1',
            'ff05::1',
            'ff02::2',
            'ff0e::1:2:3:4',
            'ff01:0:0:0:0:0:0:1',
            'ff03::1',
            'ff04::1',
            'ff06::1',
            'ff08::1',
            'ff0e::1'
        ]
    },
    {
        name: 'external_ipv6',
        pattern: /\b(2[0-9A-Fa-f]{3}:[0-9A-Fa-f:]+)\b/g,
        priority: 4,
        maskValue: '[Masked External IPv6]',
        fakeDataPool: [
            '2001:0db8:85a3:0000:0000:8a2e:0370:7334',
            '2607:f0d0:1002:51::4',
            '2404:6800:4004:813::200e',
            '2a00:1450:4001:828::2003',
            '2a03:2880:f003:c07:face:b00c::2',
            '2620:0:2d0:200::7',
            '2001:4860:4860::8888',
            '2606:4700:4700::1111',
            '2001:470:0:76::2',
            '2a01:111:f400:7c00::6'
        ]
    }
]; 