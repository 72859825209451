import React, { useEffect } from 'react';
import '../styles/Pages.css';

const FooterAd: React.FC = () => {
  // Initialize ad when component mounts
  useEffect(() => {
    try {
      const timer = setTimeout(() => {
        // Initialize ad
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      }, 100);
      
      return () => clearTimeout(timer);
    } catch (error) {
      console.error('Ad loading error:', error);
    }
  }, []);

  return (
    <div className="footer-ad-container">
      <script 
        async 
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3486600369587030"
        crossOrigin="anonymous"
      ></script>
      <ins 
        className="adsbygoogle"
        style={{ display: 'block' }}
        data-ad-client="ca-pub-3486600369587030"
        data-ad-slot="9935212078"
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
    </div>
  );
};

export default FooterAd; 