import React, { useState } from 'react';
import Footer from '../../components/Footer';
import BackButton from '../../components/BackButton';
import ImageModal from '../../components/ImageModal';
import customizeBarImg from '../../assets/images/docs/customizebar.png';
import stagesProcessingImg from '../../assets/images/docs/stagesprocessing.png';
import maskedOutputImg from '../../assets/images/docs/maskedoutput.png';
import replacedOutputImg from '../../assets/images/docs/replacedoutput.png';
import summaryImg from '../../assets/images/docs/summary.png';
import '../../styles/Pages.css';

const GettingStarted: React.FC = () => {
  const [selectedImage, setSelectedImage] = useState<{ src: string; alt: string } | null>(null);

  const handleImageClick = (src: string, alt: string) => {
    setSelectedImage({ src, alt });
  };

  return (
    <div className="page-container">
      <BackButton to="/docs" text="Back to Documentation" />
      <div className="content-container">
        <article className="content-section documentation">
          <h1>Getting Started with Info-Mask</h1>
          
          <section className="doc-intro">
            <h2>What is Info-Mask?</h2>
            <p>
              Info-Mask is a powerful client-side data masking tool that helps protect sensitive information
              in your text data. Our tool processes input text through a sophisticated three-stage system,
              ensuring comprehensive detection and protection of sensitive data while maintaining data
              consistency and privacy.
            </p>
          </section>

          <section className="doc-processing">
            <h2>How It Works</h2>
            <p>
              When you input text into Info-Mask, it goes through three sequential processing stages:
            </p>
            
            <div className="doc-stage">
              <h3>1. Custom Rules Processing</h3>
              <img 
                src={customizeBarImg} 
                alt="Custom Rules Interface" 
                className="doc-image clickable" 
                onClick={() => handleImageClick(customizeBarImg, "Custom Rules Interface")}
              />
              <p>
                Create personalized masking rules by selecting specific text to mask or replace.
                You can either:
              </p>
              <ul>
                <li>Choose from predefined categories and subcategories</li>
                <li>Provide your own replacement value</li>
              </ul>
            </div>

            <div className="doc-stage">
              <h3>2. Pattern Rules Processing</h3>
              <img 
                src={stagesProcessingImg} 
                alt="Processing Stages" 
                className="doc-image clickable" 
                onClick={() => handleImageClick(stagesProcessingImg, "Processing Stages")}
              />
              <p>
                Automatically detects and masks common patterns such as:
              </p>
              <ul>
                <li>IPv4 addresses (e.g., 192.168.1.1)</li>
                <li>Email addresses</li>
                <li>Domain names</li>
                <li>Other standardized formats</li>
              </ul>
            </div>

            <div className="doc-stage">
              <h3>3. Keyword Rules Processing</h3>
              <p>
                Identifies and protects sensitive information in structured formats:
              </p>
              <ul>
                <li>JSON data</li>
                <li>Code snippets</li>
                <li>URL parameters</li>
              </ul>
            </div>
          </section>

          <section className="doc-output">
            <h2>Output and Results</h2>
            
            <div className="doc-example">
              <h3>Masking Mode</h3>
              <img 
                src={maskedOutputImg} 
                alt="Masked Output Example" 
                className="doc-image clickable" 
                onClick={() => handleImageClick(maskedOutputImg, "Masked Output Example")}
              />
              <p>
                Sensitive data is replaced with descriptive placeholders while maintaining
                the original format.
              </p>
            </div>

            <div className="doc-example">
              <h3>Replacement Mode</h3>
              <img 
                src={replacedOutputImg} 
                alt="Replaced Output Example" 
                className="doc-image clickable" 
                onClick={() => handleImageClick(replacedOutputImg, "Replaced Output Example")}
              />
              <p>
                Sensitive data is replaced with realistic but fake values that maintain
                consistency throughout the document.
              </p>
            </div>
          </section>

          <section className="doc-consistency">
            <h2>Data Consistency</h2>
            <p>
              Info-Mask maintains consistency in data masking and replacement:
            </p>
            <ul>
              <li>Support for up to 10 different values for each rule detection</li>
              <li>Same sensitive data receives the same replacement value across the entire input</li>
              <li>Consistent formatting and structure preservation</li>
              <li>Realistic replacement values that maintain data integrity</li>
            </ul>
          </section>

          <section className="doc-summary">
            <h2>Summary and Analysis</h2>
            <img 
              src={summaryImg} 
              alt="Summary Section" 
              className="doc-image clickable" 
              onClick={() => handleImageClick(summaryImg, "Summary Section")}
            />
            <p>
              The summary section provides:
            </p>
            <ul>
              <li>Visual distribution of detections across different rule types</li>
              <li>Detailed list of detected values and their replacements</li>
              <li>Ability to remove false positives and revert specific changes</li>
            </ul>
          </section>

          <section className="doc-privacy">
            <h2>Privacy First</h2>
            <p>
              Info-Mask processes all data locally in your browser. No sensitive information
              is ever sent to our servers, ensuring complete privacy and security of your data.
            </p>
          </section>
        </article>
      </div>
      {selectedImage && (
        <ImageModal
          src={selectedImage.src}
          alt={selectedImage.alt}
          onClose={() => setSelectedImage(null)}
        />
      )}
      <Footer />
    </div>
  );
};

export default GettingStarted; 