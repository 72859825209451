import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';
import '../styles/Pages.css';

const Contact: React.FC = () => {
  return (
    <div className="page-container">
      <Link to="/" className="back-to-home">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
          <path d="M19 12H5M12 19l-7-7 7-7"/>
        </svg>
        Back to Home
      </Link>
      <main className="content-container">
        <h1>Contact Us</h1>
        <section className="content-section">
          <p>
            We'd love to hear from you! Whether you have questions, feedback, or need assistance with Info Mask, 
            we're here to help.
          </p>
        </section>

        <section className="content-section">
          <h2>Email Us</h2>
          <p>
            Feel free to reach out to us at:{' '}
            <a href="mailto:support@info-mask.com">support@info-mask.com</a>
          </p>
        </section>

        <section className="content-section">
          <h2>Need Help?</h2>
          <p>
            Check out our{' '}
            <Link to="/docs">documentation</Link>
            {' '}to get to know how the tool works and how you can use it.
          </p>
        </section>

        <section className="content-section">
          <h2>Follow Us</h2>
          <p>
            Stay connected and updated by following us on social media:
          </p>
          <p>
            YouTube:{' '}
            <a href="https://www.youtube.com/@info-mask" target="_blank" rel="noopener noreferrer">
              @info-mask
            </a>
          </p>
        </section>
      </main>
      <Footer excludeFooterAd={true} />
    </div>
  );
};

export default Contact; 