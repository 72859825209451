import { IPv4Pattern } from '../../types/patterns';

export const MAC_PATTERNS: IPv4Pattern[] = [
    {
        name: 'mac_address',
        pattern: /\b(?:[0-9A-F]{2}[:-]){5}[0-9A-F]{2}\b/gi,
        priority: 1,
        maskValue: '[Masked MAC Address]',
        fakeDataPool: [
            'FF:FF:FF:FF:FF:FF',
            '00:1A:2B:3C:4D:5E',
            'AC:DE:48:00:11:22',
            '1A:2B:3C:4D:5E:6F',
            'B8:27:EB:00:00:00',
            'DC:A6:32:AA:BB:CC',
            '00:50:56:C0:00:01',
            'E4:B9:7A:DD:EE:FF',
            '08:00:27:00:00:00',
            '00:0C:29:11:22:33'
        ]
    }
]; 