import React, { useState } from 'react';
import MaskingSections from './MaskingSections';
import Footer from './Footer';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { MaskingService } from '../utils/maskingService';
import { CATEGORIES } from '../utils/categories';
import { useGoogleAds } from '../hooks/useGoogleAds';
import logoWebp from '../assets/images/logo.webp';
import '../styles/ToolPage.css';

// Register Chart.js components
ChartJS.register(ArcElement, Tooltip, Legend);

const ToolPage: React.FC = () => {
  const [inputText, setInputText] = useState('');
  const [outputText, setOutputText] = useState('');
  const [maskingService] = useState(() => new MaskingService());
  const [showCopyButton, setShowCopyButton] = useState(false);
  const [copyButtonText, setCopyButtonText] = useState('Copy');
  
  // State for custom rules
  const [customValue, setCustomValue] = useState('');
  const [customReplacement, setCustomReplacement] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubcategory, setSelectedSubcategory] = useState('');
  const [customRules, setCustomRules] = useState<{ value: string; replacement: string; subcategory: string }[]>([]);

  // State for chart data
  const [chartData, setChartData] = useState<{
    labels: string[];
    datasets: {
      data: number[];
      backgroundColor: string[];
      borderColor: string[];
      borderWidth: number;
    }[];
  } | null>(null);

  // Initialize ads
  useGoogleAds();

  const handleClear = () => {
    setInputText('');
    setOutputText('');
    setChartData(null);
    maskingService.reset();
    setShowCopyButton(false);
  };

  const handleAddText = () => {
    if (customValue && selectedCategory) {
      setCustomRules([...customRules, { 
        value: customValue, 
        replacement: customReplacement,
        subcategory: selectedSubcategory 
      }]);
      setCustomValue('');
      setCustomReplacement('');
      setSelectedCategory('');
      setSelectedSubcategory('');
    }
  };

  const handleMask = () => {
    if (!inputText.trim()) return;
    maskingService.reset();
    const maskedText = maskingService.processText(inputText, customRules, true);
    const formattedOutput = maskedText.replace(/\n/g, '<br>');
    setOutputText(formattedOutput);
    setShowCopyButton(true);
    updateChartData();
  };

  const handleReplace = () => {
    if (!inputText.trim()) return;
    maskingService.reset();
    const replacedText = maskingService.processText(inputText, customRules, false);
    const formattedOutput = replacedText.replace(/\n/g, '<br>');
    setOutputText(formattedOutput);
    setShowCopyButton(true);
    updateChartData();
  };

  const handleRemoveRule = (originalValue: string) => {
    // First try to remove from masking service (for pattern and keyword rules)
    const newOutput = maskingService.removeRule(originalValue);
    if (newOutput) {
      setOutputText(newOutput);
      updateChartData();
      return;
    }

    // If not found in masking service, check custom rules
    const customRuleIndex = customRules.findIndex(rule => rule.value === originalValue);
    if (customRuleIndex !== -1) {
      const remainingRules = customRules.filter((_, i) => i !== customRuleIndex);
      
      // Reprocess the text with the remaining rules
      maskingService.reset();
      const isMasking = outputText.includes('[Masked');
      const newText = maskingService.processText(inputText, remainingRules, isMasking);
      const formattedOutput = newText.replace(/\n/g, '<br>');
      
      // Update state and chart
      setCustomRules(remainingRules);
      setOutputText(formattedOutput);
      setChartData({
        labels: ['Custom Rules', 'Pattern Rules', 'Keyword Rules'],
        datasets: [{
          data: [remainingRules.length, maskingService.getMappings().patterns.length, maskingService.getMappings().keywords.length],
          backgroundColor: [
            'rgba(88, 166, 255, 0.2)',
            'rgba(240, 136, 62, 0.2)',
            'rgba(248, 81, 73, 0.2)'
          ],
          borderColor: [
            'rgb(88, 166, 255)',
            'rgb(240, 136, 62)',
            'rgb(248, 81, 73)'
          ],
          borderWidth: 1
        }]
      });
    }
  };

  const handleCustomRuleRemove = (index: number) => {
    const remainingRules = customRules.filter((_, i) => i !== index);
    
    // Reprocess the text with the remaining rules
    maskingService.reset();
    const isMasking = outputText.includes('[Masked');
    const newText = maskingService.processText(inputText, remainingRules, isMasking);
    const formattedOutput = newText.replace(/\n/g, '<br>');
    
    // Update state and chart
    setCustomRules(remainingRules);
    setOutputText(formattedOutput);
    setChartData({
      labels: ['Custom Rules', 'Pattern Rules', 'Keyword Rules'],
      datasets: [{
        data: [remainingRules.length, maskingService.getMappings().patterns.length, maskingService.getMappings().keywords.length],
        backgroundColor: [
          'rgba(88, 166, 255, 0.2)',
          'rgba(240, 136, 62, 0.2)',
          'rgba(248, 81, 73, 0.2)'
        ],
        borderColor: [
          'rgb(88, 166, 255)',
          'rgb(240, 136, 62)',
          'rgb(248, 81, 73)'
        ],
        borderWidth: 1
      }]
    });
  };

  const handleCopy = () => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = outputText.replace(/&quot;/g, '"').replace(/<br>/g, '\n');
    const textContent = tempDiv.textContent || tempDiv.innerText || '';
    navigator.clipboard.writeText(textContent);
    
    const copyButton = document.querySelector('.copy-button');
    copyButton?.classList.add('copied');
    setCopyButtonText('Copied!');
    
    setTimeout(() => {
      setCopyButtonText('Copy');
      copyButton?.classList.remove('copied');
    }, 1500);
  };

  const updateChartData = () => {
    const mappings = maskingService.getMappings();
    setChartData({
      labels: ['Custom Rules', 'Pattern Rules', 'Keyword Rules'],
      datasets: [{
        data: [customRules.length, mappings.patterns.length, mappings.keywords.length],
        backgroundColor: [
          'rgba(88, 166, 255, 0.2)',
          'rgba(240, 136, 62, 0.2)',
          'rgba(248, 81, 73, 0.2)'
        ],
        borderColor: [
          'rgb(88, 166, 255)',
          'rgb(240, 136, 62)',
          'rgb(248, 81, 73)'
        ],
        borderWidth: 1
      }]
    });
  };

  return (
    <div className="page-container">
      <main className="home-container">
        <header className="header-section">
          <div className="header-content">
            <img src={logoWebp} alt="Info-Mask Logo" className="logo" />
            <h1 className="title">Info-Mask</h1>
            <p className="header-description">
              Protect your sensitive data with intelligent masking powered by advanced pattern recognition.
              Built for developers and security professionals who value privacy and compliance.
            </p>
            <div className="header-features">
              <span className="feature-tag">Consistent Data Replacement</span>
              <span className="feature-tag">Real-time Masking</span>
              <span className="feature-tag">Custom Rules</span>
            </div>
          </div>
        </header>

        <MaskingSections />
        
        <div className="tool-horizontal-ad">
          <script 
            async 
            src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3486600369587030"
            crossOrigin="anonymous"
          ></script>
          <ins 
            className="adsbygoogle"
            style={{ display: 'block' }}
            data-ad-client="ca-pub-3486600369587030"
            data-ad-slot="3675492877"
            data-ad-format="auto"
            data-full-width-responsive="true"
          ></ins>
        </div>
        
        <section className="custom-mask-container">
          <div className="custom-mask-input">
            <input
              type="text"
              placeholder="Enter text to mask..."
              className="mask-input"
              value={customValue}
              onChange={(e) => setCustomValue(e.target.value)}
            />
            <div className="select-wrapper">
              <label htmlFor="category-select" className="visually-hidden">Select Category</label>
              <select
                id="category-select"
                className="mask-select"
                value={selectedCategory}
                onChange={(e) => {
                  setSelectedCategory(e.target.value);
                  setSelectedSubcategory('');
                }}
              >
                <option value="">Select Category</option>
                {CATEGORIES.map((category, index) => (
                  <option key={index} value={category.name}>{category.name}</option>
                ))}
              </select>
            </div>
            {selectedCategory === 'Custom Rule' ? (
              <input
                type="text"
                placeholder="Enter replacement value..."
                className="mask-input"
                value={selectedSubcategory}
                onChange={(e) => setSelectedSubcategory(e.target.value)}
              />
            ) : (
              <div className="select-wrapper">
                <label htmlFor="subcategory-select" className="visually-hidden">Select Subcategory</label>
                <select
                  id="subcategory-select"
                  className="mask-select"
                  value={selectedSubcategory}
                  onChange={(e) => setSelectedSubcategory(e.target.value)}
                >
                  <option value="">Select Subcategory</option>
                  {CATEGORIES.find(cat => cat.name === selectedCategory)?.subcategories.map((subcategory, index) => (
                    <option key={index} value={subcategory.name}>{subcategory.name}</option>
                  ))}
                </select>
              </div>
            )}
            <button className="add-mask-button" onClick={handleAddText}>
              Add Rule
            </button>
          </div>
          
          <div className="custom-masks-list">
            {customRules.map((rule, index) => (
              <div key={index} className="custom-rule">
                <span className="rule-value">{rule.value}</span>
                <span className="rule-arrow">→</span>
                <span className="rule-subcategory">{rule.replacement || rule.subcategory}</span>
                <button
                  onClick={() => handleCustomRuleRemove(index)}
                  className="remove-rule-button"
                >
                  ×
                </button>
              </div>
            ))}
          </div>
        </section>

        <div className="tool-horizontal-ad">
          <script 
            async 
            src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3486600369587030"
            crossOrigin="anonymous"
          ></script>
          <ins 
            className="adsbygoogle"
            style={{ display: 'block' }}
            data-ad-client="ca-pub-3486600369587030"
            data-ad-slot="3675492877"
            data-ad-format="auto"
            data-full-width-responsive="true"
          ></ins>
        </div>

        <section className="main-content">
          <div className="text-box">
            <textarea
              placeholder={`{
  "timestamp": "2025-04-02T12:34:56Z",
  "username": "john_doe",
  "token": "abc123xyz789",
  "ip": "192.168.1.100",
  "domain": "example.com",
  "event": "login_success"
}

We also detect patterns, 25.10.25.120

Developer? We do can help with that too.
headers = {
    "Authorization": f"Bearer {ACCESS_TOKEN}",
}`}
              className="text-area"
              value={inputText}
              onChange={(e) => setInputText(e.target.value)}
              spellCheck={false}
            />
          </div>
          <div className="text-box">
            {outputText ? (
              <pre
                className="output-content"
                dangerouslySetInnerHTML={{ __html: outputText }}
              />
            ) : (
              <textarea
                readOnly
                placeholder={`{
  "timestamp": "2025-04-02T12:34:56Z",
  "username": "[Masked Username]",
  "token": "[Masked Token]",
  "ip": "[Masked Internal IPv4]",
  "domain": "[Masked Domain Name]",
  "event": "login_success"
}

We also detect patterns, [Masked External IPv4]

Developer? We do can help with that too.
headers = {
    "Authorization": [Masked Auth]",
}`}
                className="text-area"
                spellCheck={false}
              />
            )}
          </div>
        </section>

        <div className="button-container">
          <button className="compare-button" onClick={handleMask}>
            Mask
          </button>
          <button className="compare-button" onClick={handleReplace}>
            Replace
          </button>
          <button className="clear-button" onClick={handleClear}>
            Clear
          </button>
          {showCopyButton && (
            <button
              className="copy-button"
              onClick={handleCopy}
            >
              {copyButtonText}
            </button>
          )}
        </div>

        {chartData && (
          <section className="summary-container">
            <h2>Rules Distribution</h2>
            <div className="summary-content">
              <div className="chart-container">
                <Pie
                  data={chartData}
                  options={{
                    plugins: {
                      legend: {
                        position: 'left' as const,
                        labels: {
                          color: '#F0F6FC'
                        }
                      }
                    },
                    maintainAspectRatio: false
                  }}
                />
              </div>
              <div className="rules-container">
                <div className="rules-column">
                  <h3 className="rules-title">Custom Rules</h3>
                  <div className="rules-list">
                    {customRules.map((rule, index) => (
                      <div key={index} className="rule-item">
                        <button
                          onClick={() => handleCustomRuleRemove(index)}
                          className="remove-rule-button"
                          title="Remove rule"
                        >
                          ×
                        </button>
                        <span className="rule-value">{rule.value}</span>
                        <span className="rule-arrow">→</span>
                        <span className="rule-masked">{rule.replacement || rule.subcategory}</span>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="rules-column">
                  <h3 className="rules-title">Pattern Rules</h3>
                  <div className="rules-list">
                    {maskingService.getMappings().patterns.map((mapping, index) => (
                      <div key={index} className="rule-item">
                        <button
                          onClick={() => handleRemoveRule(mapping.original)}
                          className="remove-rule-button"
                          title="Remove rule"
                        >
                          ×
                        </button>
                        <span className="rule-value">{mapping.original}</span>
                        <span className="rule-arrow">→</span>
                        <span className="rule-masked">{mapping.masked}</span>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="rules-column">
                  <h3 className="rules-title">Keyword Rules</h3>
                  <div className="rules-list">
                    {maskingService.getMappings().keywords.map((mapping, index) => (
                      <div key={index} className="rule-item">
                        <button
                          onClick={() => handleRemoveRule(mapping.original)}
                          className="remove-rule-button"
                          title="Remove rule"
                        >
                          ×
                        </button>
                        <span className="rule-value">{mapping.original}</span>
                        <span className="rule-arrow">→</span>
                        <span className="rule-masked">{mapping.masked}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
        
        <div className="tool-horizontal-ad">
          <script 
            async 
            src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3486600369587030"
            crossOrigin="anonymous"
          ></script>
          <ins 
            className="adsbygoogle"
            style={{ display: 'block' }}
            data-ad-client="ca-pub-3486600369587030"
            data-ad-slot="3675492877"
            data-ad-format="auto"
            data-full-width-responsive="true"
          ></ins>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default ToolPage;