import React from 'react';
import { Link } from 'react-router-dom';

interface BackButtonProps {
  to: string;
  text?: string;
}

const BackButton: React.FC<BackButtonProps> = ({ to, text = "Back" }) => {
  return (
    <Link to={to} className="back-to-home">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
        <path d="M19 12H5M12 19l-7-7 7-7"/>
      </svg>
      {text}
    </Link>
  );
};

export default BackButton; 