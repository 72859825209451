import React, { useState } from 'react';
import { useGoogleAds } from '../hooks/useGoogleAds';

type AdFormat = 'horizontal' | 'vertical' | 'square';

interface AdBannerProps {
  className?: string;
  format: AdFormat;
  desktopOnly?: boolean;
}

const AdBanner: React.FC<AdBannerProps> = ({ 
  className = '', 
  format = 'horizontal',
  desktopOnly = false
}) => {
  const [isMobile, setIsMobile] = useState(false);
  const [adSlot, setAdSlot] = useState('');

  // Initialize ads
  useGoogleAds();

  // Set ad slot based on format
  React.useEffect(() => {
    switch(format) {
      case 'vertical':
        setAdSlot('2362411203');
        break;
      case 'square':
        setAdSlot('5691282129');
        break;
      case 'horizontal':
      default:
        setAdSlot('3675492877');
        break;
    }
  }, [format]);

  // Check if device is mobile
  React.useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };
    
    // Initial check
    checkMobile();
    
    // Add event listener for window resize
    window.addEventListener('resize', checkMobile);
    
    // Cleanup event listener
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  // Don't render if it's mobile and desktopOnly is true
  if (isMobile && desktopOnly) {
    return null;
  }

  const formatClassName = `ad-${format}`;
  
  // Set minimum dimensions based on format
  let minHeight = '120px';
  let minWidth = '100%';
  
  if (format === 'vertical') {
    minHeight = '600px';
    minWidth = '160px';
  } else if (format === 'square') {
    minHeight = '280px';
    minWidth = '336px';
  }

  return (
    <div className={`ad-container ${formatClassName} ${className}`}>
      <script 
        async 
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3486600369587030"
        crossOrigin="anonymous"
      ></script>
      <ins 
        className="adsbygoogle"
        style={{ 
          display: 'block',
          minHeight, 
          minWidth,
          background: 'rgba(0, 0, 0, 0.05)'
        }}
        data-ad-client="ca-pub-3486600369587030"
        data-ad-slot={adSlot}
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
    </div>
  );
};

export default AdBanner; 