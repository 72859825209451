import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../../../components/Footer';
import '../../../styles/Pages.css';

const KeywordRules: React.FC = () => {
  return (
    <div className="page-container">
      <Link to="/docs" className="back-to-home">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
          <path d="M19 12H5M12 19l-7-7 7-7"/>
        </svg>
        Back to Documentation
      </Link>
      <main className="content-container">
        <article className="content-section documentation">
          <h1>Keyword-Based Detection</h1>
          
          <section className="doc-intro">
            <h2>Understanding Keyword Rules</h2>
            <p>
              Keyword rules are designed to detect sensitive information in both structured logs and code
              by identifying specific variable names, properties, or patterns commonly used in software
              development and system logs.
            </p>
          </section>

          <section className="doc-section">
            <h2>How Keyword Rules Work</h2>
            <p>
              Each keyword rule consists of:
            </p>
            <ul className="spaced-list">
              <li>Two regex patterns: one for log files and one for code files</li>
              <li>A mask value for replacing sensitive data</li>
              <li>A pool of realistic fake values for replacement mode</li>
            </ul>
            <p>
              The rules are context-aware, meaning they can detect sensitive information differently in:
            </p>
            <ul className="spaced-list">
              <li>Log files (using JSON-like format: "key": "value")</li>
              <li>Code files (using assignment format: variable = value)</li>
            </ul>
          </section>

          <section className="doc-section">
            <h2>Available Keyword Rules</h2>
            
            <div className="rule-category">
              <h3>Authentication & Security</h3>
              
              <h4>Keys and Secrets</h4>
              <p>Detects various types of security keys and secrets:</p>
              <ul className="spaced-list">
                <li><strong>Keys:</strong> Matches variable names containing 'key' or 'keys'
                  <br /><em>Example: api_key, secret_key, access_key</em>
                </li>
                <li><strong>Secrets:</strong> Identifies variables containing 'secret'
                  <br /><em>Example: client_secret, app_secret, jwt_secret</em>
                </li>
                <li><strong>Tokens:</strong> Detects token-related variables
                  <br /><em>Example: auth_token, access_token, refresh_token</em>
                </li>
                <li><strong>Authentication:</strong> Matches auth-related variables
                  <br /><em>Example: auth_code, oauth_token, bearer_token</em>
                </li>
                <li><strong>Passwords:</strong> Identifies password fields
                  <br /><em>Example: user_password, admin_pass, login_password</em>
                </li>
              </ul>

              <h4>Identity & Access</h4>
              <p>Detects user and system identifiers:</p>
              <ul className="spaced-list">
                <li><strong>Usernames:</strong> Matches username or user_name variables
                  <br /><em>Example: admin_username, system_user_name</em>
                </li>
                <li><strong>Hostnames:</strong> Identifies hostname or host_name variables
                  <br /><em>Example: server_hostname, db_host_name</em>
                </li>
              </ul>
            </div>

            <div className="rule-category">
              <h3>Organization & Infrastructure</h3>
              
              <h4>Company Information</h4>
              <p>Detects organizational identifiers:</p>
              <ul className="spaced-list">
                <li><strong>Domain Names:</strong> Matches domain-related variables
                  <br /><em>Example: company_domain, site_domain</em>
                </li>
                <li><strong>Organization Names:</strong> Identifies company and organization names
                  <br /><em>Example: tenant_name, organization_name, company_name, site_name</em>
                </li>
              </ul>
            </div>

            <div className="rule-category">
              <h3>Location Data</h3>
              
              <h4>Coordinates</h4>
              <p>Detects geographical coordinates:</p>
              <ul className="spaced-list">
                <li><strong>Latitude:</strong> Matches valid latitude values (-90 to 90)
                  <br /><em>Example: user_latitude: 40.7128</em>
                </li>
                <li><strong>Longitude:</strong> Matches valid longitude values (-180 to 180)
                  <br /><em>Example: location_longitude: -74.0060</em>
                </li>
              </ul>
            </div>
          </section>

          <section className="doc-section">
            <h2>Pattern Matching Details</h2>
            <p>
              Keyword rules use sophisticated pattern matching that:
            </p>
            <ul className="spaced-list">
              <li>Handles both quoted and unquoted values</li>
              <li>Supports camelCase, snake_case, and other common naming conventions</li>
              <li>Matches values in both JSON-like structures and code assignments</li>
              <li>Validates numerical ranges for coordinates</li>
              <li>Ignores partial matches within larger words</li>
            </ul>
          </section>

          <section className="doc-section">
            <h2>Best Practices</h2>
            <ul className="spaced-list">
              <li>Use keyword rules in combination with pattern rules for better coverage</li>
              <li>Review detected keywords to remove any false positives</li>
              <li>Consider adding custom rules for organization-specific naming conventions</li>
              <li>Use replacement mode to maintain data format consistency</li>
              <li>Pay special attention to security-related keywords in configuration files</li>
            </ul>
          </section>
        </article>
      </main>
      <Footer />
    </div>
  );
};

export default KeywordRules; 