import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

declare global {
  interface Window {
    dataLayer: Array<Record<string, unknown>>;
    gtag: (command: string, ...args: unknown[]) => void;
  }
}

export const GA_TRACKING_ID = 'G-58XFJ4W1BW';

export const useGoogleAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    const consentChoice = localStorage.getItem('cookieConsent');
    
    // Only track page views if consent is given and GA is loaded
    if (consentChoice === 'accepted' && window.gtag) {
      window.gtag('config', GA_TRACKING_ID, {
        page_path: location.pathname + location.search,
        cookie_flags: 'SameSite=None;Secure',
        cookie_domain: window.location.hostname,
        cookie_expires: 2592000 // 30 days in seconds
      });
    }
  }, [location]);
}; 