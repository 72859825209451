import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';
import '../styles/Pages.css';

const PrivacyPolicy: React.FC = () => {
  return (
    <div className="page-container">
      <Link to="/" className="back-to-home">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
          <path d="M19 12H5M12 19l-7-7 7-7"/>
        </svg>
        Back to Home
      </Link>
      <main className="content-container">
        <h1>Privacy Policy</h1>
        <section className="content-section">
          <h2>Last Updated: January 8, 2025</h2>
          <p>
            This Privacy Policy describes how Info-Mask ("we," "us," or "our") collects, uses, and handles your information
            when you use our website and services. We are committed to protecting your privacy and being transparent about
            our practices.
          </p>
        </section>

        <section className="content-section">
          <h2>Data Processing</h2>
          <p>
            Info-Mask processes all masking and replacement operations directly in your browser. We do not store, collect,
            or transmit any of the text data you input for masking. All data processing operations are performed locally
            on your device, ensuring complete privacy of your sensitive information.
          </p>
        </section>

        <section className="content-section">
          <h2>Information Collection</h2>
          <p>
            While we don't collect any of your masked data, we do use the following third-party services:
          </p>
          <ul>
            <li>
              <strong>Google Analytics:</strong>
              <ul className="sub-list">
                <li>Collects anonymous usage statistics</li>
                <li>Tracks website traffic and user behavior</li>
                <li>Uses cookies to distinguish unique users</li>
                <li>IP addresses are anonymized</li>
              </ul>
            </li>
            <li>
              <strong>Google AdSense:</strong>
              <ul className="sub-list">
                <li>Displays personalized advertisements</li>
                <li>Uses cookies to serve relevant ads based on your interests</li>
                <li>Collects and processes information about your ad interactions</li>
                <li>May use your location to serve region-specific ads</li>
                <li>Subject to Google's privacy policy and terms of service</li>
              </ul>
            </li>
            <li>
              <strong>YouTube Services:</strong>
              <ul className="sub-list">
                <li>Used for hosting tutorial videos</li>
                <li>May set cookies when viewing videos</li>
                <li>Subject to Google's privacy policy</li>
              </ul>
            </li>
          </ul>
        </section>

        <section className="content-section">
          <h2>Cookies and Tracking</h2>
          <p>
            We use essential cookies and tracking technologies through Google Analytics and Google AdSense to improve our service,
            display relevant advertisements, and understand how users interact with our website. These technologies collect:
          </p>
          <ul>
            <li>Pages visited and time spent on pages</li>
            <li>Browser type and operating system</li>
            <li>Referring websites</li>
            <li>Anonymous usage patterns</li>
            <li>Ad preferences and interactions</li>
            <li>General location data for regional ad targeting</li>
          </ul>
          <p>
            You can opt-out of Google Analytics tracking by using the Google Analytics Opt-out Browser Add-on.
            For Google AdSense personalization, you can adjust your ad preferences through Google's Ad Settings
            or manage cookie preferences in your browser settings.
          </p>
        </section>

        <section className="content-section">
          <h2>Advertising</h2>
          <p>
            We use Google AdSense to display advertisements on our website:
          </p>
          <ul>
            <li>Third-party vendors, including Google, use cookies to serve ads based on your prior visits to our website and other sites</li>
            <li>Google's use of advertising cookies enables it and its partners to serve ads based on your interests and browsing history</li>
            <li>We do not directly collect any personal information through the advertising services</li>
            <li>You can opt out of personalized advertising by visiting Google's Ads Settings</li>
            <li>You can learn more about how Google uses data when you use our site by visiting <a href="https://policies.google.com/technologies/partner-sites" target="_blank" rel="noopener noreferrer">Google's Privacy & Terms</a></li>
          </ul>
        </section>

        <section className="content-section">
          <h2>Data Security</h2>
          <p>
            We implement several security measures to protect your privacy:
          </p>
          <ul>
            <li>All masking operations are performed client-side in your browser</li>
            <li>No sensitive data is transmitted to our servers</li>
            <li>Temporary browser storage is used only during active sessions and cleared upon closing</li>
            <li>Secure HTTPS encryption for all web traffic</li>
            <li>Regular security audits and updates</li>
          </ul>
        </section>

        <section className="content-section">
          <h2>Your Rights</h2>
          <p>
            You have the right to:
          </p>
          <ul>
            <li>Opt-out of analytics tracking</li>
            <li>Disable cookies through your browser settings</li>
            <li>Request information about what data we collect</li>
            <li>Request deletion of any personal data we may have</li>
          </ul>
        </section>

        <section className="content-section">
          <h2>Children's Privacy</h2>
          <p>
            Our service is not directed to children under 13. We do not knowingly collect personal information from
            children under 13. If you are a parent or guardian and believe we may have collected information about
            a child, please contact us.
          </p>
        </section>

        <section className="content-section">
          <h2>Contact Information</h2>
          <p>Contact us at: support@info-mask.com</p>
        </section>

        <section className="content-section">
          <h2>Changes to This Policy</h2>
          <p>
            We may update this privacy policy from time to time to reflect changes in our practices or for other
            operational, legal, or regulatory reasons. We will notify you of any material changes by posting the
            new policy on this page with an updated effective date.
          </p>
          <p>
            Your continued use of Info-Mask after any changes to this Privacy Policy constitutes your acceptance
            of the updated policy. We encourage you to review this policy periodically.
          </p>
        </section>
      </main>
      <Footer excludeFooterAd={true} />
    </div>
  );
};

export default PrivacyPolicy; 