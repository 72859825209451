import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../../../components/Footer';
import ImageModal from '../../../components/ImageModal';
import SEOHead from '../../../components/SEOHead';
import '../../../styles/Pages.css';

// Import actual images
import soc1Img from '../../../assets/images/SOC1.png';
import soc2Img from '../../../assets/images/SOC2.png';
import soc3Img from '../../../assets/images/SOC3.png';
import soc4Img from '../../../assets/images/SOC4.png';

const SocAnalyst: React.FC = () => {
  const [selectedImage, setSelectedImage] = useState<{ src: string; alt: string } | null>(null);

  const handleImageClick = (src: string, alt: string) => {
    setSelectedImage({ src, alt });
  };

  return (
    <div className="page-container">
      <SEOHead
        title="SOC Analyst Guide - Info-Mask Documentation"
        description="Learn how to safely analyze security logs with AI using Info-Mask. A comprehensive guide for SOC analysts on protecting sensitive data while leveraging AI capabilities."
        canonicalUrl="https://info-mask.com/docs/use-cases/soc-analyst"
        ogImage="https://info-mask.com/images/soc-analyst-guide.png"
      />
      <Link to="/docs" className="back-to-home">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
          <path d="M19 12H5M12 19l-7-7 7-7"/>
        </svg>
        Back to Documentation
      </Link>
      <main className="content-container">
        <h1>SOC Analyst Guide: Safe Log Analysis with AI</h1>
        
        <section className="content-section">
          <h2>Overview</h2>
          <p>
            As a SOC Analyst, you often need to analyze complex log files and may want to leverage AI for faster analysis.
            However, sharing raw logs with AI can expose sensitive information. This guide shows you how to safely use
            Info-Mask to protect sensitive data while still getting valuable insights from AI analysis.
          </p>
        </section>

        <section className="content-section">
          <h2>Step 1: Processing the Log</h2>
          <img 
            src={soc1Img} 
            alt="Initial Log Processing" 
            className="doc-image clickable" 
            onClick={() => handleImageClick(soc1Img, "Initial Log Processing")}
          />
          <p>
            Start by pasting your log data into Info-Mask and clicking "Replace". This will replace sensitive
            information with realistic-looking but fake data while maintaining the log's structure.
          </p>
        </section>

        <section className="content-section">
          <h2>Step 2: Reviewing Detections</h2>
          <img 
            src={soc2Img} 
            alt="Reviewing Detections" 
            className="doc-image clickable" 
            onClick={() => handleImageClick(soc2Img, "Reviewing Detections")}
          />
          <p>
            Review the detected items to ensure accuracy. In this example, we found a false positive
            where "VPN Login Successful" was incorrectly detected. Remove any false detections by
            clicking the "X" button next to them.
          </p>
        </section>

        <section className="content-section">
          <h2>Step 3: Getting the Protected Output</h2>
          <img 
            src={soc3Img} 
            alt="Protected Output" 
            className="doc-image clickable small-image" 
            onClick={() => handleImageClick(soc3Img, "Protected Output")}
          />
          <p>
            Copy the processed output. All sensitive information like domain names, IP addresses,
            UUIDs, and usernames have been replaced with safe, anonymized values.
          </p>
        </section>

        <section className="content-section">
          <h2>Step 4: AI Analysis</h2>
          <img 
            src={soc4Img} 
            alt="AI Analysis" 
            className="doc-image clickable" 
            onClick={() => handleImageClick(soc4Img, "AI Analysis")}
          />
          <p>
            Paste the protected log into your AI chat with a prompt like:
            "Provide me a summary of what happened, mention key values and provide these values also in json format"
          </p>
          <p>
            The AI can now analyze the log's structure and events while all sensitive information
            remains protected.
          </p>
        </section>

        <section className="content-section">
          <h2>Benefits</h2>
          <ul>
            <li>Safely leverage AI for log analysis without exposing sensitive data</li>
            <li>Protect company assets: domains, IPs, UUIDs, usernames, and more</li>
            <li>Maintain log structure and relationships for accurate analysis</li>
            <li>Quick understanding of complex log events</li>
            <li>Compliance with security policies when using AI tools</li>
          </ul>
        </section>
      </main>
      {selectedImage && (
        <ImageModal
          src={selectedImage.src}
          alt={selectedImage.alt}
          onClose={() => setSelectedImage(null)}
        />
      )}
      <Footer />
    </div>
  );
};

export default SocAnalyst; 