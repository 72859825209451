import { PatternMasker } from './patternMasking';
import { IPv4_PATTERNS } from './patterns/ipv4Patterns';
import { IPv6_PATTERNS } from './patterns/ipv6Patterns';
import { KEYWORD_PATTERNS } from './patterns/keywordPatterns';
import { CATEGORIES } from './categories';
import { MAC_PATTERNS } from './patterns/macPatterns';
import { AWS_PATTERNS } from './patterns/awsPatterns';
import { UUID_PATTERNS } from './patterns/uuidPatterns';
import { CREDIT_CARD_PATTERNS } from './patterns/creditCardPatterns';
import { DN_PATTERNS } from './patterns/dnPatterns';
import { JWT_PATTERNS } from './patterns/jwtPatterns';
import { DOMAIN_PATTERNS } from './patterns/domainPatterns';
import { Pattern } from '../types/patterns';

interface CustomRule {
  value: string;
  subcategory: string;
}

interface KeywordPattern {
  name: string;
  logPattern: RegExp;
  codePattern: RegExp;
  maskValue: string;
  priority?: number;
  fakeDataPool: string[];
}

export class MaskingService {
  // Cache regex patterns
  private static readonly MASKED_REGEX = /^\[.*\]$/;

  // Memoize escapeRegExp results
  private regexCache: Map<string, RegExp> = new Map();

  private getRegExp(pattern: string): RegExp {
    let regex = this.regexCache.get(pattern);
    if (!regex) {
      regex = new RegExp(escapeRegExp(pattern), 'g');
      this.regexCache.set(pattern, regex);
    }
    return regex;
  }

  private patternMasker: PatternMasker;
  private keywordMasker: PatternMasker;
  private customMasker: PatternMasker;
  private globalValueMap: Map<string, { 
    masked: string, 
    type: string, 
    priority: number,
    category: 'patterns' | 'keywords' | 'customs'
  }>;

  // Add tracking of used fake values per pattern type
  private usedFakeValues: Map<string, Set<string>> = new Map();

  constructor() {
    this.patternMasker = new PatternMasker();
    this.keywordMasker = new PatternMasker();
    this.customMasker = new PatternMasker();
    this.globalValueMap = new Map();
    this.usedFakeValues = new Map();
  }

  private addToGlobalMap(value: string, masked: string, type: string, priority: number, category: 'patterns' | 'keywords' | 'customs') {
    // Check for existing case-insensitive match
    const lowerValue = value.toLowerCase();
    const existingEntry = Array.from(this.globalValueMap.entries())
      .find(([key]) => key.toLowerCase() === lowerValue);

    if (existingEntry) {
      // Use existing masked value for consistency
      if (priority <= existingEntry[1].priority) {
        this.globalValueMap.set(value, {
          masked: existingEntry[1].masked,
          type,
          priority: existingEntry[1].priority,
          category
        });
      }
    } else {
      // If there's an existing entry with higher priority, use its masked value for consistency
      this.globalValueMap.set(value, {
        masked: masked,
        type,
        priority,
        category
      });
    }
  }

  private isValueAlreadyDetected(value: string): boolean {
    // Check case-insensitive match in global map
    const lowerValue = value.toLowerCase();
    return Array.from(this.globalValueMap.entries())
      .some(([key]) => key.toLowerCase() === lowerValue);
  }

  private encodeHTML(str: string): string {
    return str.replace(/[&<>"']/g, match => {
      const enc: { [key: string]: string } = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#39;'
      };
      return enc[match];
    });
  }

  private wrapValue(value: string, className: string): string {
    // Value is already encoded when it reaches here
    return `<span class="${className}">${value}</span>`;
  }

  // Optimize value checking
  private isMaskedValue(value: string): boolean {
    return MaskingService.MASKED_REGEX.test(value);
  }

  private getFakeValue(pattern: Pattern): string {
    const patternKey = pattern.name;
    if (!this.usedFakeValues.has(patternKey)) {
      this.usedFakeValues.set(patternKey, new Set());
    }
    const usedValues = this.usedFakeValues.get(patternKey)!;
    
    // Filter out already used values
    const availableValues = pattern.fakeDataPool.filter(value => !usedValues.has(value));
    
    // If all values are used, clear the set and start over
    if (availableValues.length === 0) {
      usedValues.clear();
      return this.getFakeValue(pattern);
    }

    // Get random value from remaining available values
    const fakeValue = availableValues[Math.floor(Math.random() * availableValues.length)];
    usedValues.add(fakeValue);
    return fakeValue;
  }

  private convertKeywordToPattern(keywordPattern: KeywordPattern, value: string): Pattern {
    return {
      name: keywordPattern.name,
      pattern: new RegExp(escapeRegExp(value), 'g'),
      maskValue: keywordPattern.maskValue,
      priority: keywordPattern.priority ?? 10,
      fakeDataPool: keywordPattern.fakeDataPool
    };
  }

  private processKeywordPatterns(text: string, shouldMask: boolean): string {
    let result = text;

    KEYWORD_PATTERNS.forEach(pattern => {
        // Process log pattern
        result = result.replace(pattern.logPattern, (_match, _paramName, quotedValue, unquotedValue) => {
        const value = quotedValue || unquotedValue;
            const cleanValue = value.replace(/^["']|["']$/g, '');
            
            if (this.isMaskedValue(cleanValue)) {
                return _match;
            }

            // Use default priority 10 for keyword patterns if undefined
            const priority = pattern.priority ?? 10;

            if (!this.isValueAlreadyDetected(cleanValue)) {
              if (shouldMask) {
                this.keywordMasker.addMapping({
                  original: value,
                  masked: pattern.maskValue,
                  type: pattern.name
                });
                this.addToGlobalMap(value, pattern.maskValue, pattern.name, priority, 'keywords');
              } else {
                const patternForValue = this.convertKeywordToPattern(pattern, value);
                const fakeValue = this.getFakeValue(patternForValue);
                this.keywordMasker.addMapping({
                  original: value,
                  masked: fakeValue,
                  type: pattern.name
                });
                this.addToGlobalMap(value, fakeValue, pattern.name, priority, 'keywords');
              }
            }
            return _match;
        });

        // Process code pattern
        result = result.replace(pattern.codePattern, (_match, _paramName, _quotedGroup, quotedValue, unquotedValue) => {
            const value = quotedValue || unquotedValue;
            if (!value) return _match;
            
            const cleanValue = value.replace(/^["']|["']$/g, '');
            
            if (this.isMaskedValue(cleanValue)) {
                return _match;
            }

            // Use default priority 10 for keyword patterns if undefined
            const priority = pattern.priority ?? 10;

            if (!this.isValueAlreadyDetected(cleanValue)) {
              if (shouldMask) {
                this.keywordMasker.addMapping({
                  original: value,
                  masked: pattern.maskValue,
                  type: pattern.name
                });
                this.addToGlobalMap(value, pattern.maskValue, pattern.name, priority, 'keywords');
              } else {
                const patternForValue = this.convertKeywordToPattern(pattern, value);
                const fakeValue = this.getFakeValue(patternForValue);
                this.keywordMasker.addMapping({
                  original: value,
                  masked: fakeValue,
                  type: pattern.name
                });
                this.addToGlobalMap(value, fakeValue, pattern.name, priority, 'keywords');
              }
            }
            return _match;
        });
    });

    return result;
  }

  private validateInput(text: string): boolean {
    if (typeof text !== 'string') {
      console.error('Invalid input: text must be a string');
      return false;
    }
    if (text.length > 1000000) { // Adjust size limit as needed
      console.error('Input text too large');
      return false;
    }
    return true;
  }

  public processText(text: string, customRules: CustomRule[], shouldMask: boolean): string {
    if (!this.validateInput(text)) {
      return '';
    }

    // Don't encode the input text yet - process rules first
    const result = text;
    this.globalValueMap.clear();

    // Process all rules first
    this.processCustomRules(result, customRules, shouldMask);
    this.processAllPatternRules(result, shouldMask);
    this.processKeywordPatterns(result, shouldMask);

    // Batch all replacements and encode HTML at the same time
    const allMappings = [...this.globalValueMap.entries()]
      .sort((a, b) => b[0].length - a[0].length);

    // First encode the base text
    const encodedText = this.encodeHTML(result);

    // Then apply all replacements with encoded values
    return allMappings.reduce((acc, [original, mapping]) => {
      const regex = this.getRegExp(this.encodeHTML(original));
      const wrapped = this.wrapValue(
        mapping.masked, 
        shouldMask ? 'masked-value' : 'replaced-value'
      );
      return acc.replace(regex, wrapped);
    }, encodedText);
  }

  // Helper method to process pattern rules consistently
  private processPatternRules(text: string, patterns: Pattern[], shouldMask: boolean): string {
    const result = text;
    
    patterns.forEach(pattern => {
      if (!this.validatePattern(pattern)) {
        console.warn('Invalid pattern detected and skipped');
        return;
      }
      const matches = this.patternMasker.findMatches(result, pattern.pattern);
      if (matches.length > 0) {
        matches.forEach(match => {
          if (this.isMaskedValue(match.value)) {
            return;
          }

          if (!this.isValueAlreadyDetected(match.value)) {
            if (shouldMask) {
              this.patternMasker.addMapping({
                original: match.value,
                masked: pattern.maskValue,
                type: pattern.name
              });
              this.addToGlobalMap(match.value, pattern.maskValue, pattern.name, pattern.priority, 'patterns');
            } else {
              const fakeValue = this.getFakeValue(pattern);
              this.patternMasker.addMapping({
                original: match.value,
                masked: fakeValue,
                type: pattern.name
              });
              this.addToGlobalMap(match.value, fakeValue, pattern.name, pattern.priority, 'patterns');
            }
          }
        });
      }
    });

    return result;
  }

  private processIPv4Patterns(text: string, shouldMask: boolean): string {
    const result = text;
    
    const sortedPatterns = [...IPv4_PATTERNS].sort((a, b) => a.priority - b.priority);

    sortedPatterns.forEach(pattern => {
      const matches = this.patternMasker.findMatches(result, pattern.pattern);
      if (matches.length > 0) {
        matches.forEach(match => {
          if (this.isMaskedValue(match.value)) {
            return;
          }

          if (!this.isValueAlreadyDetected(match.value)) {
            match.type = pattern.name;
            if (shouldMask) {
              this.patternMasker.addMapping({
                original: match.value,
                masked: pattern.maskValue,
                type: pattern.name
              });
              this.addToGlobalMap(match.value, pattern.maskValue, pattern.name, pattern.priority, 'patterns');
            } else {
              const fakeValue = this.getFakeValue(pattern);
              this.patternMasker.addMapping({
                original: match.value,
                masked: fakeValue,
                type: pattern.name
              });
              this.addToGlobalMap(match.value, fakeValue, pattern.name, pattern.priority, 'patterns');
            }
          }
        });
      }
    });

    return result;
  }

  public reset() {
    this.patternMasker.reset();
    this.keywordMasker.reset();
    this.customMasker.reset();
    this.globalValueMap.clear();
    this.usedFakeValues.clear();  // Clear used fake values on reset
  }

  public getMappings() {
    const allMappings = Array.from(this.globalValueMap.entries());
    const consolidatedMappings = new Map<string, { 
      original: string, 
      masked: string, 
      type: string 
    }>();
    
    // Consolidate mappings by lowercase value
    allMappings.forEach(([original, mapping]) => {
      const lowerKey = original.toLowerCase();
      if (!consolidatedMappings.has(lowerKey)) {
        consolidatedMappings.set(lowerKey, {
          original, // Keep first occurrence's original case
          masked: mapping.masked,
          type: mapping.type
        });
      }
    });
    
    return {
      patterns: allMappings
        .filter(([, mapping]) => mapping.category === 'patterns')
        .reduce((acc, [original]) => {
          const lowerKey = original.toLowerCase();
          const consolidated = consolidatedMappings.get(lowerKey);
          if (consolidated && !acc.some(m => m.original.toLowerCase() === lowerKey)) {
            acc.push({
              original: consolidated.original,
              masked: consolidated.masked,
              type: consolidated.type
            });
          }
          return acc;
        }, [] as Array<{ original: string; masked: string; type: string }>),
      keywords: allMappings
        .filter(([, mapping]) => mapping.category === 'keywords')
        .reduce((acc, [original]) => {
          const lowerKey = original.toLowerCase();
          const consolidated = consolidatedMappings.get(lowerKey);
          if (consolidated && !acc.some(m => m.original.toLowerCase() === lowerKey)) {
            acc.push({
              original: consolidated.original,
              masked: consolidated.masked,
              type: consolidated.type
            });
          }
          return acc;
        }, [] as Array<{ original: string; masked: string; type: string }>),
      customs: allMappings
        .filter(([, mapping]) => mapping.category === 'customs')
        .reduce((acc, [original]) => {
          const lowerKey = original.toLowerCase();
          const consolidated = consolidatedMappings.get(lowerKey);
          if (consolidated && !acc.some(m => m.original.toLowerCase() === lowerKey)) {
            acc.push({
              original: consolidated.original,
              masked: consolidated.masked,
              type: consolidated.type
            });
          }
          return acc;
        }, [] as Array<{ original: string; masked: string; type: string }>)
    };
  }

  private validateCustomRule(rule: CustomRule): boolean {
    if (!rule || typeof rule.value !== 'string' || typeof rule.subcategory !== 'string') {
      return false;
    }
    return true;
  }

  private processCustomRules(text: string, customRules: CustomRule[], shouldMask: boolean): string {
    customRules.forEach(rule => {
      if (!this.validateCustomRule(rule)) {
        console.warn('Invalid custom rule detected and skipped');
        return;
      }
      // Create case-insensitive regex from the value
      const valueRegex = new RegExp(escapeRegExp(rule.value), 'gi');
      
      if (this.isMaskedValue(rule.value)) {
        return;
      }

      const category = CATEGORIES.find(cat => 
        cat.subcategories.some(sub => sub.name === rule.subcategory)
      );

      let replacementValue: string;

      if (shouldMask) {
        if (category) {
          replacementValue = category.subcategories.find(
            sub => sub.name === rule.subcategory
          )?.maskedValue || '[Masked Custom]';
        } else {
          replacementValue = '[Masked Custom]';
        }
      } else {
        if (category) {
          const subcategory = category.subcategories.find(
            sub => sub.name === rule.subcategory
          );
          if (subcategory && subcategory.fakeData.length > 0) {
            // Create a fake pattern for custom rules to track used values
            const customPattern: Pattern = {
              name: `custom_${rule.subcategory}`,
              pattern: valueRegex,
              maskValue: '[Masked Custom]',
              priority: 1,
              fakeDataPool: subcategory.fakeData
            };
            replacementValue = this.getFakeValue(customPattern);
          } else {
            replacementValue = rule.subcategory;
          }
        } else {
          replacementValue = rule.subcategory;
        }
      }

      // Find all case variations in the text
      const matches = text.match(valueRegex);
      if (matches) {
        matches.forEach(match => {
          if (!this.isMaskedValue(match)) {
            this.customMasker.addMapping({
              original: match,
              masked: replacementValue,
              type: 'custom'
            });
            this.addToGlobalMap(match, replacementValue, 'custom', 1, 'customs');
          }
        });
      }
    });

    return text;
  }

  private processAllPatternRules(text: string, shouldMask: boolean): string {
    let result = text;
    
    // Process all pattern types in order of specificity
    result = this.processPatternRules(result, DOMAIN_PATTERNS, shouldMask);
    result = this.processPatternRules(result, JWT_PATTERNS, shouldMask);
    result = this.processPatternRules(result, DN_PATTERNS, shouldMask);
    result = this.processPatternRules(result, CREDIT_CARD_PATTERNS, shouldMask);
    result = this.processPatternRules(result, UUID_PATTERNS, shouldMask);
    result = this.processPatternRules(result, AWS_PATTERNS, shouldMask);
    result = this.processPatternRules(result, MAC_PATTERNS, shouldMask);
    result = this.processPatternRules(result, IPv6_PATTERNS, shouldMask);
    result = this.processIPv4Patterns(result, shouldMask);

    return result;
  }

  public removeRule(originalValue: string): string | undefined {
    // First try exact match
    let mapping = this.globalValueMap.get(originalValue);
    
    // If not found, try with quotes removed
    if (!mapping) {
        const cleanValue = originalValue.replace(/^["']|["']$/g, '');
        mapping = this.globalValueMap.get(cleanValue);
    }
    
    if (!mapping) return undefined;
    
    // 2. Remove from specific storage
    if (mapping.category === 'patterns') {
        this.patternMasker.removeMapping(originalValue);
    } else if (mapping.category === 'keywords') {
        this.keywordMasker.removeMapping(originalValue);
    } else if (mapping.category === 'customs') {
        this.customMasker.removeMapping(originalValue);
    }

    // 3. Store the masked/replaced value before removing from global map
    const valueToReplace = mapping.masked;
    this.globalValueMap.delete(originalValue);

    // 4. Get current output text and replace the wrapped masked/replaced value with original
    const outputContent = document.querySelector('.output-content');
    if (!outputContent) return undefined;
    
    const currentOutput = outputContent.innerHTML;
    
    // Handle both quoted and unquoted versions
    const cleanOriginal = originalValue.replace(/^["']|["']$/g, '');
    const encodedValue = this.encodeHTML(valueToReplace);
    
    // Create a more flexible regex that handles potential HTML encoding
    const regexPattern = `<span class="(masked|replaced)-value">${escapeRegExp(encodedValue)}</span>`;
    return currentOutput.replace(
        new RegExp(regexPattern, 'g'),
        this.encodeHTML(cleanOriginal)
    );
  }

  private validatePattern(pattern: Pattern): pattern is Pattern {
    return (
      pattern &&
      pattern.pattern instanceof RegExp &&
      typeof pattern.name === 'string' &&
      typeof pattern.maskValue === 'string' &&
      typeof pattern.priority === 'number' &&
      Array.isArray(pattern.fakeDataPool)
    );
  }
}

function escapeRegExp(string: string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}