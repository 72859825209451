import { IPv4Pattern } from '../../types/patterns';

export const UUID_PATTERNS: IPv4Pattern[] = [
    {
        name: 'uuid',
        pattern: /\b[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}\b/gi,
        priority: 1,
        maskValue: '[Masked UUID]',
        fakeDataPool: [
            '123e4567-e89b-12d3-a456-426614174000',
            '550e8400-e29b-41d4-a716-446655440000',
            'a0eebc99-9c0b-4ef8-bb6d-6bb9bd380a11',
            '6fa459ea-ee8a-3ca4-894e-db77e160355e',
            'f47ac10b-58cc-4372-a567-0e02b2c3d479',
            'd9428888-122b-11e1-b85c-61cd3cbb3210',
            'c9107b85-45dc-4ace-9eae-5d86cd48f5e6',
            'b5c89721-5d5c-4c42-b6bf-3a5a3c88c1e9',
            'e7f309b9-2f19-4c7d-91c8-7b8c698f7e2d',
            'a1b2c3d4-e5f6-47g8-h9i0-j1k2l3m4n5o6'
        ]
    }
]; 