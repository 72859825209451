import React, { useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Footer from '../components/Footer';
import BackButton from '../components/BackButton';
import AdBanner from '../components/AdBanner';
import '../styles/Pages.css';

interface BlogPost {
  id: string;
  title: string;
  date: string;
  summary: string;
  categories: string[];
}

const blogPosts: BlogPost[] = [
  {
    id: 'deepseek-leak-2025',
    title: "Major Security Breach: DeepSeek's Database Exposed",
    date: 'January 29, 2025',
    summary: 'Wiz Research uncovers a critical security breach in DeepSeek\'s infrastructure, exposing millions of chat logs and sensitive data. Learn about the implications for AI security.',
    categories: ['Data Breach', 'AI Security']
  },
  {
    id: 'nhs-data-ai-2024',
    title: "UK's NHS Data: Powering the Next AI Revolution",
    date: 'January 2025',
    summary: 'Explore how the UK government plans to leverage NHS health data to fuel AI innovation while maintaining privacy and security controls.',
    categories: ['Data Privacy', 'AI Security']
  },
  {
    id: 'ai-security-risks-2024',
    title: 'The Hidden Dangers: AI Security Risks in 2024',
    date: 'January 2025',
    summary: 'An in-depth look at emerging AI security threats, from chatbot privacy concerns to AI-enhanced malware and enterprise security challenges.',
    categories: ['AI Security']
  },
  {
    id: 'ai-healthcare-2024',
    title: 'The Dangers of AI Medical Advice: What You Need to Know',
    date: 'January 2025',
    summary: 'Why relying on AI for medical advice can be dangerous and how to properly utilize AI in healthcare while protecting your privacy.',
    categories: ['AI Security']
  },
  {
    id: 'ai-future-security-2024',
    title: 'The Future of AI Security: Emerging Threats and Solutions in 2024',
    date: 'January 2025',
    summary: 'An exploration of emerging AI security threats, evolving privacy concerns, and next-generation security measures that organizations need to prepare for.',
    categories: ['AI Security']
  },
  {
    id: 'ai-legal-compliance-2024',
    title: 'AI and Data Protection Laws: Navigating the Legal Landscape in 2024',
    date: 'January 2025',
    summary: 'A comprehensive overview of legal requirements and compliance frameworks for organizations implementing AI systems, with special focus on data protection.',
    categories: ['Data Protection']
  },
  {
    id: 'secure-ai-practices-2024',
    title: 'Secure AI Implementation: A Guide to Protecting Your Organization',
    date: 'January 2025',
    summary: 'A comprehensive guide to implementing AI securely in your organization, covering essential security measures, employee training, and technical safeguards.',
    categories: ['AI Security']
  },
  {
    id: 'microsoft-ai-leak-2024',
    title: '38 Terabytes of AI Research Data Exposed: The Microsoft AI Incident',
    date: 'January 2025',
    summary: 'An in-depth look at how Microsoft AI researchers accidentally exposed 38 terabytes of private data and the lessons learned for the AI industry.',
    categories: ['Data Breach']
  },
  {
    id: 'unauthorized-ai-use-2024',
    title: 'The Silent Risk: Unauthorized AI Tool Usage in the Workplace',
    date: 'January 2025',
    summary: 'Discover how unauthorized AI tool usage by employees creates significant security risks and what organizations can do to protect themselves.',
    categories: ['AI Security']
  },
  {
    id: 'ai-data-breaches-2024',
    title: 'The Hidden Dangers: AI Chatbots and Data Breaches in 2024',
    date: 'January 2025',
    summary: 'Recent incidents reveal how AI chatbots can inadvertently expose sensitive information and what organizations can do to protect themselves.',
    categories: ['Data Breach']
  },
  // More posts will be added here
];

const Blog: React.FC = () => {
  // State for active category filter
  const [activeCategory, setActiveCategory] = useState<string>('All');
  
  // Extract all unique categories
  const allCategories = useMemo(() => {
    const categoriesSet = new Set<string>();
    blogPosts.forEach(post => {
      post.categories.forEach(category => {
        categoriesSet.add(category);
      });
    });
    return ['All', ...Array.from(categoriesSet)];
  }, []);
  
  // Filter posts based on selected category
  const filteredPosts = useMemo(() => {
    if (activeCategory === 'All') {
      return blogPosts;
    }
    return blogPosts.filter(post => 
      post.categories.includes(activeCategory)
    );
  }, [activeCategory]);
  
  // Handle category click
  const handleCategoryClick = (category: string) => {
    setActiveCategory(category);
  };

  // Render blog posts with ads every 3 posts
  const renderBlogPosts = () => {
    if (filteredPosts.length === 0) {
      return (
        <div className="content-section">
          <p>No articles found in this category.</p>
        </div>
      );
    }

    return filteredPosts.map((post, index) => {
      const blogPostElement = (
        <div key={post.id} className="content-section blog-post-preview">
          <h2>
            <Link to={`/blog/${post.id}`}>{post.title}</Link>
          </h2>
          <div className="post-meta">
            <span className="post-date">{post.date}</span>
            {post.categories.map((category, catIndex) => (
              <span 
                key={catIndex} 
                className="post-category"
                onClick={(e) => {
                  e.preventDefault();
                  handleCategoryClick(category);
                }}
              >
                {category}
              </span>
            ))}
          </div>
          <p className="post-summary">{post.summary}</p>
          <Link to={`/blog/${post.id}`} className="read-more">
            Read More →
          </Link>
        </div>
      );

      // Add ad banner after every 3 posts (but not after the last post if it's also a multiple of 3)
      if ((index + 1) % 3 === 0 && index < filteredPosts.length - 1) {
        return (
          <React.Fragment key={`${post.id}-fragment`}>
            {blogPostElement}
            <AdBanner format="horizontal" className="blog-ad" />
          </React.Fragment>
        );
      }

      return blogPostElement;
    });
  };

  return (
    <div className="page-container">
      <Helmet>
        <title>AI Security & Data Privacy Blog | Info-Mask</title>
        <meta name="description" content="Stay informed about the latest in AI security, data privacy, and information protection. Expert insights on keeping your sensitive data safe in the AI era." />
        <meta name="keywords" content="AI security blog, data privacy articles, information protection, data masking, PII protection, AI data leaks, cybersecurity blog" />
        <link rel="canonical" href="https://info-mask.com/blog" />
        
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://info-mask.com/blog" />
        <meta property="og:title" content="AI Security & Data Privacy Blog | Info-Mask" />
        <meta property="og:description" content="Stay informed about the latest in AI security, data privacy, and information protection. Expert insights on keeping your sensitive data safe in the AI era." />
        <meta property="og:image" content="https://info-mask.com/images/blog-header.jpg" />
        
        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://info-mask.com/blog" />
        <meta property="twitter:title" content="AI Security & Data Privacy Blog | Info-Mask" />
        <meta property="twitter:description" content="Stay informed about the latest in AI security, data privacy, and information protection. Expert insights on keeping your sensitive data safe in the AI era." />
        <meta property="twitter:image" content="https://info-mask.com/images/blog-header.jpg" />
      </Helmet>
      
      <BackButton to="/" text="Back to Home" />
      
      <div className="content-container">
        <h1>Latest Articles on AI Security & Data Privacy</h1>
        <p className="blog-intro">
          Expert insights on protecting sensitive information in the age of AI. Learn about the latest security threats, 
          data privacy best practices, and how to safeguard your information when using AI tools.
        </p>
        
        <div className="blog-categories">
          {allCategories.map((category, index) => (
            <span 
              key={index} 
              className={`category-tag ${activeCategory === category ? 'active' : ''}`}
              onClick={() => handleCategoryClick(category)}
              tabIndex={0}
              role="button"
              aria-pressed={activeCategory === category}
              onKeyDown={(e) => e.key === 'Enter' && handleCategoryClick(category)}
            >
              {category}
            </span>
          ))}
        </div>
        
        <div className="article-content-wrapper">
          <div className="blog-posts">
            {renderBlogPosts()}
          </div>
          
          <aside className="article-sidebar">
            <AdBanner format="vertical" desktopOnly={true} className="sidebar-ad" />
          </aside>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Blog; 