import React, { useState } from 'react';
import Footer from '../../components/Footer';
import BackButton from '../../components/BackButton';
import ImageModal from '../../components/ImageModal';
import falsePositive1Img from '../../assets/images/docs/falsepositive1.png';
import fpSummarySectionImg from '../../assets/images/docs/fpSummarysection.png';
import removeFPImg from '../../assets/images/docs/removeFP.png';
import fpRemovedCCImg from '../../assets/images/docs/fpremovedCC.png';
import '../../styles/Pages.css';

const RemovingFalseDetections: React.FC = () => {
  const [selectedImage, setSelectedImage] = useState<{ src: string; alt: string } | null>(null);

  const handleImageClick = (src: string, alt: string) => {
    setSelectedImage({ src, alt });
  };

  return (
    <div className="page-container">
      <BackButton to="/docs" text="Back to Documentation" />
      <div className="content-container">
        <article className="content-section documentation">
          <h1>Removing False Detections</h1>
          
          <section className="doc-intro">
            <h2>Understanding False Detections</h2>
            <p>
              Sometimes, Info-Mask might incorrectly identify certain values as sensitive data.
              This guide will show you how to remove these false detections from your results.
            </p>
          </section>

          <section className="doc-stage">
            <h3>1. Initial Detection</h3>
            <img 
              src={falsePositive1Img} 
              alt="Initial False Detection" 
              className="doc-image clickable" 
              onClick={() => handleImageClick(falsePositive1Img, "Initial False Detection")}
            />
            <p>
              After entering your input and clicking "Mask", you might notice that some values are incorrectly detected.
              In this example, the parameter "principalId" was incorrectly detected as a Credit Card number.
            </p>
          </section>

          <section className="doc-stage">
            <h3>2. Locating the False Detection</h3>
            <img 
              src={fpSummarySectionImg} 
              alt="Summary Section" 
              className="doc-image clickable" 
              onClick={() => handleImageClick(fpSummarySectionImg, "Summary Section")}
            />
            <p>
              Scroll down to the summary section to find the falsely detected value.
              Here you can see the value incorrectly identified as a credit card.
            </p>
          </section>

          <section className="doc-stage">
            <h3>3. Removing the False Detection</h3>
            <img 
              src={removeFPImg} 
              alt="Removing False Detection" 
              className="doc-image clickable" 
              onClick={() => handleImageClick(removeFPImg, "Removing False Detection")}
            />
            <p>
              Click the "X" button next to the rule you want to remove.
              This will remove the false detection from your results.
            </p>
          </section>

          <section className="doc-stage">
            <h3>4. Updated Results</h3>
            <img 
              src={fpRemovedCCImg} 
              alt="Updated Results" 
              className="doc-image clickable" 
              onClick={() => handleImageClick(fpRemovedCCImg, "Updated Results")}
            />
            <p>
              The output will automatically update to show the original value that was falsely detected.
              There's no need to click "Mask" or "Replace" again - the changes are applied dynamically.
            </p>
          </section>

          <section className="doc-note">
            <h3>Important Note</h3>
            <p>
              Please be aware that if you click "Mask" or "Replace" again after removing a false detection,
              the value may be detected again. The removal of false detections applies only to the current
              processing session.
            </p>
          </section>
        </article>
      </div>
      {selectedImage && (
        <ImageModal
          src={selectedImage.src}
          alt={selectedImage.alt}
          onClose={() => setSelectedImage(null)}
        />
      )}
      <Footer />
    </div>
  );
};

export default RemovingFalseDetections; 