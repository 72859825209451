import React, { ReactNode } from 'react';
import Footer from './Footer';
import BackButton from './BackButton';
import AdBanner from './AdBanner';
import { useGoogleAds } from '../hooks/useGoogleAds';
import '../styles/Pages.css';

interface BlogArticleProps {
  title: string;
  date: string;
  categories?: string[];
  children: ReactNode;
}

const BlogArticle: React.FC<BlogArticleProps> = ({ 
  title, 
  date, 
  categories = [], 
  children 
}) => {
  // Initialize ads
  useGoogleAds();

  return (
    <div className="page-container">
      <BackButton to="/blog" text="Back to Blog" />
      <div className="content-container">
        <div className="article-content-wrapper">
          <article className="content-section blog-post">
            <h1>{title}</h1>
            <div className="post-meta">
              <span className="post-date">{date}</span>
              {categories.map((category, index) => (
                <span key={index} className="post-category">{category}</span>
              ))}
            </div>

            <div className="post-content">
              {children}
            </div>
            
            <div className="article-multiplex-ad">
              <script 
                async 
                src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3486600369587030"
                crossOrigin="anonymous"
              ></script>
              <ins 
                className="adsbygoogle"
                style={{ display: 'block' }}
                data-ad-format="autorelaxed"
                data-ad-client="ca-pub-3486600369587030"
                data-ad-slot="3235675024"
              ></ins>
            </div>
          </article>
          
          <aside className="article-sidebar">
            <AdBanner 
              format="vertical" 
              className="article-vertical-ad" 
              desktopOnly={true}
            />
          </aside>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default BlogArticle; 