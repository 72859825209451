import { IPv4Pattern } from '../../types/patterns';
import domainList from '../../assets/domainlist/domainlist.txt?raw';

// Process the TLD list from the imported content
const tldList = domainList
    .split('\n')
    .filter(Boolean)
    .map(tld => tld.trim())
    .map(tld => tld.replace('.', ''))  // Remove the leading dot
    .join('|');

// Create two patterns: one for email addresses and one for domain names
export const DOMAIN_PATTERNS: IPv4Pattern[] = [
    {
        name: 'email_address',
        pattern: new RegExp(`\\b[a-zA-Z0-9._%+-]+@[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]\\.((?:${tldList})(?:\\.(?:${tldList}))*)\\b`, 'gi'),
        priority: 2,
        maskValue: '[Masked Email Address]',
        fakeDataPool: [
            'user@example.com',
            'contact@testdomain.net',
            'info@mocksite.org',
            'admin@sample.io',
            'support@demo.cloud',
            'hello@company.tech',
            'service@business.co',
            'team@startup.app',
            'office@enterprise.dev',
            'help@platform.site'
        ]
    },
    {
        name: 'domain_name',
        pattern: new RegExp(`\\b(?<!@|[a-zA-Z0-9._%+-]@)[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]\\.((?:${tldList})(?:\\.(?:${tldList}))*)\\b`, 'gi'),
        priority: 3,
        maskValue: '[Masked Domain Name]',
        fakeDataPool: [
            'example.com',
            'testdomain.net',
            'mocksite.org',
            'sample.io',
            'demo.cloud',
            'company.tech',
            'business.co',
            'startup.app',
            'enterprise.dev',
            'platform.site'
        ]
    }
]; 