import { IPv4Pattern } from '../../types/patterns';

export const JWT_PATTERNS: IPv4Pattern[] = [
    {
        name: 'jwt',
        pattern: /\b([A-Za-z0-9_-]{8,})\.([A-Za-z0-9_-]{8,})\.([A-Za-z0-9_-]{8,})\b/g,
        priority: 1,
        maskValue: '[Masked JWT]',
        fakeDataPool: [
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiMTIzIn0.abCdEfGhIjK',
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX25hbWUiOiJKb2huIn0.YzAbCdEfGh',
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjEyMzQ1In0.XYZabcD12Ef',
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjEyMzQiLCJyb2xlIjoiYWRtaW4ifQ.AbCdEfGhIjK',
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiNzg5In0.a1B2C3D4E5F',
            'eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIn0.MIIBIjANBgkq',
            'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJzZXNzaW9uX2lkIjoiYWJjZCJ9.RSA1234567',
            'eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0ZWFtX2lkIjoiMTIzNCJ9.ES256abcdef',
            'eyJhbGciOiJQUzM4NCIsInR5cCI6IkpXVCJ9.eyJncm91cF9pZCI6Ijc3NyJ9.PS384xyz123',
            'eyJhbGciOiJIUzM4NCIsInR5cCI6IkpXVCJ9.eyJyb2xlX2lkIjoiOTk5In0.HS384pqrstu'
        ]
    }
]; 