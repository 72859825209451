import React from 'react';

const MaskingSections: React.FC = () => {
  return (
    <section className="masking-sections">
      <div className="masking-section">
        <h2>Patterns</h2>
        <p>Automatically detects and masks common patterns in your data.</p>
        <div className="pattern-status">
          <span>Email</span>
          <span>Domain</span>
          <span>IPv4/IPv6</span>
          <span>MAC Address</span>
          <span>Credit Card</span>
          <span>JWT Token</span>
          <span>UUID</span>
          <span>AWS ARN</span>
          <span>DN</span>
        </div>
      </div>

      <div className="masking-section">
        <h2>Keywords</h2>
        <p>Identifies and masks sensitive information based on parameter names.</p>
        <div className="pattern-status">
          <span>Password</span>
          <span>Token</span>
          <span>Secret</span>
          <span>Key</span>
          <span>Auth</span>
          <span>Domain</span>
          <span>Company Name</span>
          <span>Username</span>
          <span>Hostname</span>
          <span>Longitude</span>
          <span>Latitude</span>
        </div>
      </div>

      <div className="masking-section">
        <h2>Custom Rules</h2>
        <p>Create your own masking rules with custom categories.</p>
        <div className="pattern-status">
          <span>Personal Info</span>
          <span>Business Info</span>
          <span>Network Identifiers</span>
          <span>Custom Text</span>
        </div>
      </div>
    </section>
  );
};

export default MaskingSections;