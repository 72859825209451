import { IPv4Pattern } from '../../types/patterns';

export const IPv4_PATTERNS: IPv4Pattern[] = [
  {
    name: 'subnet_mask',
    pattern: /\b(255\.(?:0|255)\.(?:0|255)\.(?:0|255))\b/g,
    priority: 1,
    maskValue: '[Masked Subnet Mask]',
    fakeDataPool: [
      '255.255.255.0',
      '255.255.0.0',
      '255.0.0.0',
      '255.255.255.128',
      '255.255.255.192',
      '255.255.255.224',
      '255.255.255.240',
      '255.255.255.248',
      '255.255.255.252',
      '255.255.252.0'
    ]
  },
  {
    name: 'internal_ip',
    pattern: /\b(10\.(?:\d{1,3}\.){2}\d{1,3}|172\.(?:1[6-9]|2[0-9]|3[0-1])\.(?:\d{1,3}\.)\d{1,3}|192\.168\.(?:\d{1,3}\.)\d{1,3})\b/g,
    priority: 2,
    maskValue: '[Masked Internal IPv4]',
    fakeDataPool: [
      '10.0.0.1',
      '172.16.0.1',
      '192.168.1.1',
      '10.10.10.10',
      '172.20.0.100',
      '192.168.0.254',
      '10.0.1.50',
      '172.16.1.100',
      '192.168.2.200',
      '10.1.1.1'
    ]
  },
  {
    name: 'loopback',
    pattern: /\b127\.(?:\d{1,3}\.){2}\d{1,3}\b/g,
    priority: 3,
    maskValue: '[Masked Loopback IPv4]',
    fakeDataPool: [
      '127.0.0.1',
      '127.0.0.2',
      '127.0.1.1',
      '127.0.0.3',
      '127.0.1.2',
      '127.1.0.1',
      '127.0.0.4',
      '127.0.2.1',
      '127.1.1.1',
      '127.0.0.5'
    ]
  },
  {
    name: 'reserved',
    pattern: /\b(0\.(?:\d{1,3}\.){2}\d{1,3}|169\.254\.\d{1,3}\.\d{1,3}|192\.0\.0\.\d{1,3}|22[4-9]\.(?:\d{1,3}\.){2}\d{1,3}|23[0-9]\.(?:\d{1,3}\.){2}\d{1,3}|240\.(?:\d{1,3}\.){2}\d{1,3})\b/g,
    priority: 4,
    maskValue: '[Masked Reserved IPv4]',
    fakeDataPool: [
      '169.254.0.1',
      '192.0.0.1',
      '224.0.0.1',
      '240.0.0.1',
      '169.254.1.1',
      '192.0.0.2',
      '225.0.0.1',
      '240.0.0.2',
      '169.254.2.1',
      '226.0.0.1'
    ]
  },
  {
    name: 'external',
    pattern: /\b(?!10\.|172\.(?:1[6-9]|2[0-9]|3[0-1])\.|192\.168\.|127\.|169\.254\.|0\.|192\.0\.0\.|22[4-9]\.|23[0-9]\.|240\.|255\.)((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\.){3}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\b/g,
    priority: 5,
    maskValue: '[Masked External IPv4]',
    fakeDataPool: [
      '74.125.218.200',
      '198.51.100.1',
      '182.76.145.89',
      '54.241.132.90',
      '98.137.246.7',
      '203.220.45.101',
      '8.8.8.8',
      '1.1.1.1',
      '104.16.28.34',
      '185.60.219.128'
    ]
  }
]; 