import React, { useState } from 'react';
import Footer from '../../components/Footer';
import BackButton from '../../components/BackButton';
import ImageModal from '../../components/ImageModal';
import textForCustomImg from '../../assets/images/docs/textForCustom.png';
import maskedCustomRuleImg from '../../assets/images/docs/maskedCustomRule.png';
import customRuleNotmaskedImg from '../../assets/images/docs/customRuleNotmasked.png';
import customruleSSNImg from '../../assets/images/docs/customruleSSN.png';
import addressCustomRuleImg from '../../assets/images/docs/addressCUstomRule.png';
import amoungCustomImg from '../../assets/images/docs/amoungCustom.png';
import maskedCruleImg from '../../assets/images/docs/maskedCrule.png';
import replaceCcustomImg from '../../assets/images/docs/replaceCcustom.png';
import '../../styles/Pages.css';

const CustomRules: React.FC = () => {
  const [selectedImage, setSelectedImage] = useState<{ src: string; alt: string } | null>(null);

  const handleImageClick = (src: string, alt: string) => {
    setSelectedImage({ src, alt });
  };

  return (
    <div className="page-container">
      <BackButton to="/docs" text="Back to Documentation" />
      <div className="content-container">
        <article className="content-section documentation">
          <h1>Working with Custom Rules</h1>
          
          <section className="doc-intro">
            <h2>Understanding Custom Rules</h2>
            <p>
              Custom rules allow you to define specific masking patterns for sensitive data that might not be
              automatically detected or requires special handling. This guide will walk you through the process
              of creating and using custom rules effectively.
            </p>
          </section>

          <section className="doc-initial">
            <h2>Initial Text Processing</h2>
            <img 
              src={textForCustomImg} 
              alt="Initial Text Input" 
              className="doc-image clickable" 
              onClick={() => handleImageClick(textForCustomImg, "Initial Text Input")}
            />
            <p>Start by entering your text that contains sensitive information.</p>
            
            <img 
              src={maskedCustomRuleImg} 
              alt="Initial Masking Result" 
              className="doc-image clickable" 
              onClick={() => handleImageClick(maskedCustomRuleImg, "Initial Masking Result")}
            />
            <p>After clicking "Mask", you'll see the initial masking results.</p>
          </section>

          <section className="doc-undetected">
            <h2>Handling Undetected Sensitive Data</h2>
            <img 
              src={customRuleNotmaskedImg} 
              alt="Undetected Sensitive Data" 
              className="doc-image clickable" 
              onClick={() => handleImageClick(customRuleNotmaskedImg, "Undetected Sensitive Data")}
            />
            <p>
              Sometimes, sensitive data might not be automatically detected. This is where custom rules become valuable.
            </p>
          </section>

          <section className="doc-categories">
            <h2>Rule Categories</h2>
            <p>Info-Mask provides three main categories for custom rules:</p>
            <ul>
              <li>Personal Information</li>
              <li>Business Information</li>
              <li>Network Identifiers</li>
            </ul>
            <p>Each category contains subcategories that automatically generate realistic replacement data.</p>
          </section>

          <section className="doc-examples">
            <h3>Example 1: Masking Social Security Numbers</h3>
            <img 
              src={customruleSSNImg} 
              alt="SSN Custom Rule" 
              className="doc-image clickable" 
              onClick={() => handleImageClick(customruleSSNImg, "SSN Custom Rule")}
            />
            <p>
              To mask an ID number, select:
              <br />
              Personal Info → Social Security Number → Add Text
            </p>

            <h3>Example 2: Masking Addresses</h3>
            <img 
              src={addressCustomRuleImg} 
              alt="Address Custom Rule" 
              className="doc-image clickable" 
              onClick={() => handleImageClick(addressCustomRuleImg, "Address Custom Rule")}
            />
            <p>
              For billing addresses, choose:
              <br />
              Personal Info → Address → Add Text
            </p>

            <h3>Example 3: Custom Replacement Values</h3>
            <img 
              src={amoungCustomImg} 
              alt="Custom Amount Rule" 
              className="doc-image clickable" 
              onClick={() => handleImageClick(amoungCustomImg, "Custom Amount Rule")}
            />
            <p>
              When no suitable subcategory exists (like for amounts):
              <br />
              1. Select "Custom Rule"
              <br />
              2. Enter your desired replacement value (e.g., "1$")
              <br />
              3. Click "Add Rule"
            </p>
          </section>

          <section className="doc-results">
            <h2>Applying Custom Rules</h2>
            <img 
              src={maskedCruleImg} 
              alt="Masked Results" 
              className="doc-image clickable" 
              onClick={() => handleImageClick(maskedCruleImg, "Masked Results")}
            />
            <p>Click "Mask" to apply all your custom rules at once.</p>

            <h3>Replacement Mode</h3>
            <img 
              src={replaceCcustomImg} 
              alt="Replacement Results" 
              className="doc-image clickable" 
              onClick={() => handleImageClick(replaceCcustomImg, "Replacement Results")}
            />
            <p>
              Use the "Replace" option to substitute sensitive data with realistic-looking alternatives
              while maintaining consistency throughout the document.
            </p>
          </section>
        </article>
      </div>
      {selectedImage && (
        <ImageModal
          src={selectedImage.src}
          alt={selectedImage.alt}
          onClose={() => setSelectedImage(null)}
        />
      )}
      <Footer />
    </div>
  );
};

export default CustomRules; 