import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Footer from '../components/Footer';
import '../styles/Pages.css';

// Define FAQ items for both display and structured data
const faqItems = [
  {
    question: "What is a data mask?",
    answer: "A data mask is a security technique that replaces sensitive information with fictional but realistic-looking data while preserving the original format and structure. It helps protect private information while maintaining data usability for testing, development, or sharing purposes."
  },
  {
    question: "What is an example of sensitive data?",
    answer: "Sensitive data includes personally identifiable information (PII) such as email addresses, phone numbers, credit card numbers, social security numbers, IP addresses, passwords, API keys, authentication tokens, and personal health information (PHI)."
  },
  {
    question: "What is an example of masked data?",
    answer: "Masked data replaces sensitive information while maintaining the data format. For example: Email \"john.doe@company.com\" becomes \"[MASKED_EMAIL]\", IP address \"192.168.1.1\" becomes \"[MASKED_IP]\", Credit card \"4111-1111-1111-1111\" becomes \"[MASKED_CC]\"."
  },
  {
    question: "How to mask sensitive data?",
    answer: "To mask sensitive data: 1) Identify sensitive information patterns (emails, IPs, credit cards), 2) Choose appropriate masking rules, 3) Apply consistent masking patterns, 4) Verify the masked output maintains data relationships. Info-Mask automates this process with pre-built patterns and customizable rules."
  },
  {
    question: "How to protect sensitive data?",
    answer: "Protect sensitive data by: 1) Identifying and classifying sensitive information, 2) Using data masking tools like Info-Mask, 3) Implementing encryption, 4) Following security best practices, 5) Regularly auditing data handling procedures, 6) Training team members on data security."
  },
  {
    question: "Why is data security important in AI?",
    answer: "Data security in AI is crucial because AI models can memorize and potentially expose sensitive information. Protecting data prevents unauthorized access, maintains compliance with privacy regulations, preserves confidentiality, and ensures responsible AI usage while safeguarding personal and organizational information."
  },
  {
    question: "How can you keep data secure when interacting with AI?",
    answer: "Secure data when using AI by: 1) Masking sensitive information before sharing with AI platforms, 2) Using data masking tools to remove PII and confidential data, 3) Reviewing content before sharing, 4) Following organizational security policies, 5) Using client-side tools that process data locally."
  },
  {
    question: "How can we protect privacy in AI?",
    answer: "Protect privacy in AI by masking or removing sensitive information before AI interactions, using data anonymization tools, implementing proper data handling procedures, and ensuring no confidential information is shared with AI models. Tools like Info-Mask help automate this process securely."
  }
];

const FAQ: React.FC = () => {
  return (
    <div className="page-container">
      <Helmet>
        <title>Data Privacy & AI Security FAQ | Info-Mask</title>
        <meta name="description" content="Frequently asked questions about data masking, privacy protection, and AI security. Learn how to protect sensitive information when using AI tools." />
        <meta name="keywords" content="data masking FAQ, data privacy questions, AI security FAQ, PII protection, sensitive data FAQ, GDPR compliance, HIPAA compliance" />
        <link rel="canonical" href="https://info-mask.com/faq" />
        
        {/* Schema.org structured data for FAQPage */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": faqItems.map(item => ({
              "@type": "Question",
              "name": item.question,
              "acceptedAnswer": {
                "@type": "Answer",
                "text": item.answer
              }
            }))
          })}
        </script>
      </Helmet>
      
      <Link to="/" className="back-to-home">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
          <path d="M19 12H5M12 19l-7-7 7-7"/>
        </svg>
        Back to Home
      </Link>
      <main className="content-container">
        <h1>Frequently Asked Questions</h1>

        {/* Basic Concepts */}
        {faqItems.map((item, index) => (
          <section key={index} className="content-section">
            <h2>{item.question}</h2>
            {item.question === "What is an example of masked data?" ? (
              <>
          <p>
            Masked data replaces sensitive information while maintaining the data format. For example:
                </p>
                <ul className="faq-list">
                  <li>Email "john.doe@company.com" becomes "[MASKED_EMAIL]"</li>
                  <li>IP address "192.168.1.1" becomes "[MASKED_IP]"</li>
                  <li>Credit card "4111-1111-1111-1111" becomes "[MASKED_CC]"</li>
                </ul>
              </>
            ) : item.question === "How to mask sensitive data?" ? (
              <>
                <p>To mask sensitive data:</p>
                <ol className="faq-steps">
                  <li>Identify sensitive information patterns (emails, IPs, credit cards)</li>
                  <li>Choose appropriate masking rules</li>
                  <li>Apply consistent masking patterns</li>
                  <li>Verify the masked output maintains data relationships</li>
                </ol>
                <p>Info-Mask automates this process with pre-built patterns and customizable rules.</p>
              </>
            ) : item.question === "How to protect sensitive data?" ? (
              <>
                <p>Protect sensitive data by:</p>
                <ol className="faq-steps">
                  <li>Identifying and classifying sensitive information</li>
                  <li>Using data masking tools like Info-Mask</li>
                  <li>Implementing encryption</li>
                  <li>Following security best practices</li>
                  <li>Regularly auditing data handling procedures</li>
                  <li>Training team members on data security</li>
                </ol>
              </>
            ) : item.question === "How can you keep data secure when interacting with AI?" ? (
              <>
                <p>Secure data when using AI by:</p>
                <ol className="faq-steps">
                  <li>Masking sensitive information before sharing with AI platforms</li>
                  <li>Using data masking tools to remove PII and confidential data</li>
                  <li>Reviewing content before sharing</li>
                  <li>Following organizational security policies</li>
                  <li>Using client-side tools that process data locally</li>
                </ol>
              </>
            ) : (
              <p>{item.answer}</p>
            )}
        </section>
        ))}
      </main>
      <Footer />
    </div>
  );
};

export default FAQ; 