import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';
import '../styles/Pages.css';

const About: React.FC = () => {
  return (
    <div className="page-container">
      <Link to="/" className="back-to-home">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
          <path d="M19 12H5M12 19l-7-7 7-7"/>
        </svg>
        Back to Home
      </Link>
      <main className="content-container">
        <h1>About Info-Mask</h1>
        <section className="content-section">
          <h2>Our Story</h2>
          <p>
            Info-Mask was born from real-world experience in SOC (Security Operations Center) management. 
            With the increasing adoption of AI chat services by SOC analysts, we recognized the need for a simple, 
            cost-effective solution to protect sensitive data before interacting with AI platforms.
          </p>
          <p>
            As a tool designed by security professionals for security professionals, Info-Mask bridges the gap 
            between the efficiency of AI assistance and the necessity of data protection.
          </p>
        </section>

        <section className="content-section">
          <h2>Key Features</h2>
          <ul>
            <li>
              <strong>Pattern-based Detection:</strong> Automatically identifies and masks sensitive patterns in any text format:
              <ul className="sub-list">
                <li>Works across plain text, logs, and code</li>
                <li>Detects common sensitive data patterns (emails, IPs, tokens, etc.)</li>
                <li>Maintains formatting while masking sensitive information</li>
              </ul>
            </li>
            <li>
              <strong>Keyword-based Masking:</strong> Specialized for structured data:
              <ul className="sub-list">
                <li>JSON log events processing</li>
                <li>Code parameter/variable handling</li>
                <li>URL parameter masking</li>
                <li>Identifies sensitive values by parameter names</li>
              </ul>
            </li>
            <li>
              <strong>Custom Rules Engine:</strong> Flexible rule creation:
              <ul className="sub-list">
                <li>Use pre-defined categories</li>
                <li>Create custom categories</li>
                <li>Define your own masking patterns</li>
              </ul>
            </li>
            <li>
              <strong>Value Consistency:</strong> Maintains data relationships:
              <ul className="sub-list">
                <li>Consistent masking for up to 10 unique values per rule</li>
                <li>Preserves data patterns for better analysis</li>
                <li>Helps maintain context while protecting sensitive data</li>
              </ul>
            </li>
            <li>
              <strong>Interactive Summary:</strong> Comprehensive detection overview:
              <ul className="sub-list">
                <li>Visual distribution of detected patterns</li>
                <li>Review and remove false positives</li>
                <li>Track masking effectiveness</li>
              </ul>
            </li>
          </ul>
        </section>

        <section className="content-section">
          <h2>Privacy & Security</h2>
          <p>
            Info-Mask operates entirely client-side in your browser. We never collect, store, or transmit your data.
            This approach ensures that your sensitive information remains under your control while still benefiting
            from powerful masking capabilities.
          </p>
        </section>

        <section className="content-section">
          <h2>Perfect For</h2>
          <ul>
            <li>SOC Analysts working with AI platforms</li>
            <li>Security teams handling sensitive log data</li>
            <li>Developers sharing code snippets</li>
            <li>Anyone needing to mask sensitive data quickly and reliably</li>
          </ul>
        </section>

        <section className="content-section">
          <h2>Contact Us</h2>
          <p>
            For support inquiries or questions, you can reach us at:{' '}
            <a href="mailto:support@info-mask.com">support@info-mask.com</a>
          </p>
          <p>
            For tutorials and updates, visit our{' '}
            <a href="https://www.youtube.com/@info-mask" target="_blank" rel="noopener noreferrer">
              YouTube channel
            </a>
          </p>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default About; 