import { IPv4Pattern } from '../../types/patterns';

export const AWS_PATTERNS: IPv4Pattern[] = [
    {
        name: 'aws_arn',
        pattern: /\barn:aws[a-zA-Z0-9-]*:[a-zA-Z0-9-]+:[a-zA-Z0-9-]*:[0-9]{12}:[/a-zA-Z0-9-_.]+[/a-zA-Z0-9-_.:]*\b/g,
        priority: 1,
        maskValue: '[MASKED_AWS_ARN]',
        fakeDataPool: [
            'arn:aws:s3:::my-bucket-name',
            'arn:aws:iam::123456789012:role/my-role-name',
            'arn:aws:ec2:us-west-1:123456789012:instance/i-0123456789abcdef0',
            'arn:aws:lambda:us-east-1:123456789012:function:my-function-name',
            'arn:aws:sns:us-east-1:123456789012:my-sns-topic',
            'arn:aws:dynamodb:us-west-2:123456789012:table/my-table',
            'arn:aws:rds:eu-west-1:123456789012:db:my-database',
            'arn:aws:sqs:ap-south-1:123456789012:my-queue',
            'arn:aws:kms:us-east-2:123456789012:key/1234abcd-12ab-34cd-56ef-1234567890ab',
            'arn:aws:secretsmanager:eu-central-1:123456789012:secret:my-secret-123456'
        ]
    }
]; 