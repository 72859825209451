export interface Subcategory {
  name: string;
  maskedValue: string;
  fakeData: string[];
}

export interface Category {
  name: string;
  subcategories: Subcategory[];
}

export const CATEGORIES: Category[] = [
  {
    name: 'Personal Information',
    subcategories: [
      { 
        name: 'Name', 
        maskedValue: '[Masked Name]', 
        fakeData: [
          'John Doe',
          'Jane Smith',
          'James Brown',
          'Emily Johnson',
          'Michael Wilson',
          'Sarah Davis',
          'Robert Miller',
          'Lisa Anderson',
          'William Taylor',
          'Maria Garcia'
        ]
      },
      { 
        name: 'Phone Number', 
        maskedValue: '[Masked Phone]', 
        fakeData: [
          '123-456-7890',
          '987-654-3210',
          '555-123-4567',
          '444-555-6666',
          '777-888-9999',
          '333-222-1111',
          '666-777-8888',
          '222-333-4444',
          '888-999-0000',
          '111-222-3333'
        ]
      },
      { 
        name: 'Country', 
        maskedValue: '[Masked Location]', 
        fakeData: [
          'United States',
          'Canada',
          'Mexico',
          'Brazil',
          'United Kingdom',
          'France',
          'Germany',
          'Australia',
          'Japan',
          'Spain'
        ]
      },
      { 
        name: 'City', 
        maskedValue: '[Masked Location]', 
        fakeData: [
          'New York',
          'Los Angeles',
          'Chicago',
          'Toronto',
          'London',
          'Paris',
          'Berlin',
          'Sydney',
          'Tokyo',
          'Madrid'
        ]
      },
      { 
        name: 'Address', 
        maskedValue: '[Masked Address]', 
        fakeData: [
          '123 Main Street',
          '456 Elm Avenue',
          '789 Oak Boulevard',
          '321 Pine Road',
          '654 Maple Drive',
          '987 Cedar Lane',
          '147 Birch Street',
          '258 Willow Way',
          '369 Spruce Court',
          '741 Ash Place'
        ]
      },
      { 
        name: 'Credit Card Number', 
        maskedValue: '[Masked Credit Card]', 
        fakeData: [
          '4111-1111-1111-1111',
          '5500-0000-0000-0004',
          '3700-0000-0000-002',
          '3400-0000-0000-009',
          '6011-0000-0000-0004',
          '5105-1051-0510-5100',
          '3000-0000-0000-0004',
          '4012-8888-8888-1881',
          '5555-5555-5555-4444',
          '4222-2222-2222-2222'
        ]
      },
      { 
        name: 'Social Security Number', 
        maskedValue: '[Masked SSN]', 
        fakeData: [
          '123-45-6789',
          '987-65-4321',
          '111-22-3333',
          '444-55-6666',
          '777-88-9999',
          '333-22-1111',
          '666-77-8888',
          '222-33-4444',
          '888-99-0000',
          '555-66-7777'
        ]
      },
      { 
        name: 'Passport Number', 
        maskedValue: '[Masked Passport]', 
        fakeData: [
          'P12345678',
          'P87654321',
          'P11223344',
          'P99887766',
          'P44556677',
          'P33221100',
          'P55667788',
          'P77889900',
          'P22334455',
          'P66778899'
        ]
      }
    ]
  },
  {
    name: 'Business Information',
    subcategories: [
      { 
        name: 'User ID', 
        maskedValue: '[Masked User ID]', 
        fakeData: [
          'user123',
          'admin456',
          'staff789',
          'emp101',
          'manager202',
          'tech303',
          'support404',
          'dev505',
          'analyst606',
          'guest707'
        ]
      },
      { 
        name: 'API Key', 
        maskedValue: '[Masked API Key]', 
        fakeData: [
          'ak_live_12345678901234',
          'ak_test_98765432109876',
          'sk_live_abcdefghijklmn',
          'sk_test_nmlkjihgfedcba',
          'pk_live_zyxwvutsrqpon',
          'pk_test_nopqrstuvwxyz',
          'api_key_123456789abc',
          'api_key_cba987654321',
          'key_live_987654321xyz',
          'key_test_xyz123456789'
        ]
      },
      { 
        name: 'Company Name', 
        maskedValue: '[Masked Company Name]', 
        fakeData: [
          'Acme Corporation',
          'Global Industries',
          'Tech Solutions Inc',
          'Innovative Systems LLC',
          'Digital Dynamics Ltd',
          'Future Technologies Corp',
          'Smart Solutions Group',
          'Peak Performance Inc',
          'Elite Enterprises LLC',
          'Prime Industries Ltd'
        ]
      },
      { 
        name: 'Auth', 
        maskedValue: '[Masked Auth]', 
        fakeData: [
          'Bearer_Token_123456',
          'OAuth_789012345xyz',
          'Basic_Auth_abcdefg',
          'JWT_Token_hijklmn',
          'API_Auth_opqrstuv',
          'Session_wxyz1234',
          'Access_5678abcd',
          'Auth_Token_efghij',
          'Secure_klmnopqr',
          'Token_stuvwxyz'
        ]
      },
      { 
        name: 'Password', 
        maskedValue: '[Masked Password]', 
        fakeData: [
          'SecurePass123!',
          'StrongPwd456@',
          'Complex789#',
          'Safe101Pass$',
          'Unique202Pwd%',
          'Protected303^',
          'Secret404&',
          'Private505*',
          'Secure606(',
          'Hidden707)'
        ]
      },
      { 
        name: 'Key', 
        maskedValue: '[Masked Key]', 
        fakeData: [
          'key_live_12345abc',
          'key_test_xyz789',
          'secret_key_def456',
          'private_key_ghi789',
          'public_key_jkl012',
          'access_key_mno345',
          'master_key_pqr678',
          'system_key_stu901',
          'admin_key_vwx234',
          'app_key_yz567'
        ]
      },
      { 
        name: 'Token', 
        maskedValue: '[Masked Token]', 
        fakeData: [
          'tkn_live_abcd1234',
          'tkn_test_efgh5678',
          'access_tkn_ijkl90',
          'refresh_tkn_mnop12',
          'session_tkn_qrst34',
          'auth_tkn_uvwx56',
          'bearer_tkn_yz78',
          'oauth_tkn_1234ab',
          'jwt_tkn_cdef56',
          'api_tkn_ghij78'
        ]
      }
    ]
  },
  {
    name: 'Network Identifiers',
    subcategories: [
      { 
        name: 'IP Address', 
        maskedValue: '[Masked IP Address]', 
        fakeData: [
          '192.168.1.1',
          '10.0.0.1',
          '172.16.0.1',
          '192.168.0.100',
          '10.10.10.10',
          '172.20.0.50',
          '192.168.2.200',
          '10.0.1.100',
          '172.16.1.150',
          '192.168.3.250'
        ]
      },
      { 
        name: 'MAC Address', 
        maskedValue: '[Masked MAC Address]', 
        fakeData: [
          '00:11:22:33:44:55',
          'AA:BB:CC:DD:EE:FF',
          '11:22:33:44:55:66',
          'FF:EE:DD:CC:BB:AA',
          '00:1A:2B:3C:4D:5E',
          'A1:B2:C3:D4:E5:F6',
          '12:34:56:78:9A:BC',
          'DE:F0:12:34:56:78',
          '98:76:54:32:10:FF',
          '01:23:45:67:89:AB'
        ]
      },
      { 
        name: 'URL', 
        maskedValue: '[Masked URL]', 
        fakeData: [
          'hxxps://example.com',
          'hxxps://test.org',
          'hxxps://demo.net',
          'hxxps://sample.io',
          'hxxps://mock.dev',
          'hxxps://staging.app',
          'hxxps://beta.cloud',
          'hxxps://dev.site',
          'hxxps://qa.tech',
          'hxxps://uat.web'
        ]
      },
      { 
        name: 'Domain', 
        maskedValue: '[Masked Domain]', 
        fakeData: [
          'example.com',
          'test.org',
          'demo.net',
          'sample.io',
          'mock.dev',
          'staging.app',
          'beta.cloud',
          'dev.site',
          'qa.tech',
          'uat.web'
        ]
      },
      { 
        name: 'Username', 
        maskedValue: '[Masked Username]', 
        fakeData: [
          'john.doe',
          'jane.smith',
          'mike.wilson',
          'sarah.davis',
          'robert.miller',
          'lisa.anderson',
          'william.taylor',
          'maria.garcia',
          'david.brown',
          'emma.white'
        ]
      },
      { 
        name: 'Email', 
        maskedValue: '[Masked Email]', 
        fakeData: [
          'john.doe@example.com',
          'jane.smith@test.org',
          'mike.wilson@demo.net',
          'sarah.davis@sample.io',
          'robert.miller@mock.dev',
          'lisa.anderson@staging.app',
          'william.taylor@beta.cloud',
          'maria.garcia@dev.site',
          'david.brown@qa.tech',
          'emma.white@uat.web'
        ]
      }
    ]
  },
  {
    name: 'Custom Rule',
    subcategories: [] // No predefined subcategories
  }
]; 