import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useGoogleAds = () => {
  const location = useLocation();

  useEffect(() => {
    const initializeAds = () => {
      try {
        // Wait for a short time to ensure DOM is ready
        setTimeout(() => {
          const adElements = document.querySelectorAll('.adsbygoogle');
          adElements.forEach(() => {
            try {
              (window.adsbygoogle = window.adsbygoogle || []).push({});
            } catch (e) {
              console.error('Error initializing individual ad:', e);
            }
          });
        }, 100);
      } catch (error) {
        console.error('Error in Google Ads initialization:', error);
      }
    };

    // Initialize ads when component mounts or route changes
    initializeAds();

    // Add event listener for dynamic content loading
    window.addEventListener('load', initializeAds);

    return () => {
      window.removeEventListener('load', initializeAds);
    };
  }, [location.pathname]); // Re-run when route changes
}; 