import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import ToolPage from './components/ToolPage';
import HomePage from './pages/HomePage';
import About from './pages/About';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Terms from './pages/Terms';
import FAQ from './pages/FAQ';
import Blog from './pages/Blog';
import Contact from './pages/Contact';
import Sidebar from './components/Sidebar';
import AiDataBreaches2024 from './pages/blog/AiDataBreaches2024';
import UnauthorizedAiUse2024 from './pages/blog/UnauthorizedAiUse2024';
import MicrosoftAiLeak2024 from './pages/blog/MicrosoftAiLeak2024';
import SecureAiPractices2024 from './pages/blog/SecureAiPractices2024';
import AiLegalCompliance2024 from './pages/blog/AiLegalCompliance2024';
import AiFutureSecurity2024 from './pages/blog/AiFutureSecurity2024';
import NHSDataAI2024 from './pages/blog/NHSDataAI2024';
import AISecurityRisks2024 from './pages/blog/AISecurityRisks2024';
import AIHealthcare2024 from './pages/blog/AIHealthcare2024';
import ScrollToTop from './components/ScrollToTop';
import Documentation from './pages/Documentation';
import GettingStarted from './pages/docs/GettingStarted';
import CustomRules from './pages/docs/CustomRules';
import RemovingFalseDetections from './pages/docs/RemovingFalseDetections';
import SocAnalyst from './pages/docs/use-cases/SocAnalyst';
import PatternRules from './pages/docs/detection-rules/PatternRules';
import KeywordRules from './pages/docs/detection-rules/KeywordRules';
import CookieConsent from './components/CookieConsent';
import DeepseekLeak2025 from './pages/blog/DeepseekLeak2025';
import './App.css';

const App: React.FC = () => {
  return (
    <HelmetProvider>
      <Router>
        <ScrollToTop />
        <Sidebar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/tool" element={<ToolPage />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/nhs-data-ai-2024" element={<NHSDataAI2024 />} />
          <Route path="/blog/ai-security-risks-2024" element={<AISecurityRisks2024 />} />
          <Route path="/blog/ai-healthcare-2024" element={<AIHealthcare2024 />} />
          <Route path="/blog/ai-data-breaches-2024" element={<AiDataBreaches2024 />} />
          <Route path="/blog/unauthorized-ai-use-2024" element={<UnauthorizedAiUse2024 />} />
          <Route path="/blog/microsoft-ai-leak-2024" element={<MicrosoftAiLeak2024 />} />
          <Route path="/blog/secure-ai-practices-2024" element={<SecureAiPractices2024 />} />
          <Route path="/blog/ai-legal-compliance-2024" element={<AiLegalCompliance2024 />} />
          <Route path="/blog/ai-future-security-2024" element={<AiFutureSecurity2024 />} />
          <Route path="/blog/deepseek-leak-2025" element={<DeepseekLeak2025 />} />
          <Route path="/docs" element={<Documentation />} />
          <Route path="/docs/getting-started" element={<GettingStarted />} />
          <Route path="/docs/custom-rules" element={<CustomRules />} />
          <Route path="/docs/removing-false-detections" element={<RemovingFalseDetections />} />
          <Route path="/docs/use-cases/soc-analyst" element={<SocAnalyst />} />
          <Route path="/docs/detection-rules/patterns" element={<PatternRules />} />
          <Route path="/docs/detection-rules/keywords" element={<KeywordRules />} />
        </Routes>
        <CookieConsent />
      </Router>
    </HelmetProvider>
  );
};

export default App;